const a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
];
const b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
];

export function inWords(price) {
    let points = price.toFixed(2).toString().split(".")[1];
    let num = parseInt(price.toString().split(".")[0]);
    let paise = "";
    if (points && points !== "00") {
        //There is paise
        console.log("POINTS", points);
        const pArr = points.split("");
        let tens = parseInt(pArr[0]);
        let ones = parseInt(pArr[1]);
        if (pArr[0] === "1") {
            ones = parseInt(points);
        }
        paise = b[tens] + a[ones] + "paise";
    }

    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
        n[1] != 0
            ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
            : "";
    str +=
        n[2] != 0
            ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
            : "";
    str +=
        n[3] != 0
            ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
            : "";
    str +=
        n[4] != 0
            ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
            : "";
    str +=
        n[5] != 0
            ? (str != "" ? "and " : "") +
              (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]])
            : "";
    return str + "rupees " + paise + " only ";
}

export const formatPrice = (str) => {
    if (isNaN(parseFloat(str))) {
        return "0.00";
    }
    return parseFloat(str).toFixed(2).toString();
};
