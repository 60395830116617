import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TextField,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import TablePaginationActions from "../../../utils/TablePaginationActions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SalesModal from "./SalesModal";

const Row = ({
  row,
  setMaterialWithPO,
  setSelectedSalesData,
  selectedSalesData,
}) => {
  const {
    fabricName,
    colorName,
    totalSaleConsumption,
    unit,
    fabricStock,
    vendorStock,
    totalStock,
    vendorPO,
    netStock,
    skuPO,
    totalRequired,
    daysOfStockRequired,
    daysOfStockAvailable,
    totalSaleConsumptionInUnit,
    unfulfilled,
  } = row;
  // console.log("row", row);
  const name = `${fabricName} ${colorName}`;
  const handleSKUPOChange = (value) => {
    const amt = parseInt(value);
    if (isNaN(amt)) {
      setMaterialWithPO((prev) =>
        prev.map((p) => {
          if (p.fabricName === fabricName && p.colorName === colorName) {
            return {
              ...p,
              skuPO: 0,
            };
          } else {
            return p;
          }
        })
      );
    } else {
      setMaterialWithPO((prev) =>
        prev.map((p) => {
          if (p.fabricName === fabricName && p.colorName === colorName) {
            return {
              ...p,
              skuPO: amt,
            };
          } else {
            return p;
          }
        })
      );
    }
  };
  const handleVendorPOChange = (value) => {
    const amt = parseInt(value);
    if (isNaN(amt)) {
      setMaterialWithPO((prev) =>
        prev.map((p) => {
          if (p.fabricName === fabricName && p.colorName === colorName) {
            return {
              ...p,
              vendorPO: 0,
            };
          } else {
            return p;
          }
        })
      );
    } else {
      setMaterialWithPO((prev) =>
        prev.map((p) => {
          if (p.fabricName === fabricName && p.colorName === colorName) {
            return {
              ...p,
              vendorPO: amt,
            };
          } else {
            return p;
          }
        })
      );
    }
  };
  return (
    <TableRow
      key={row.code}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell align="right">{totalSaleConsumption}</TableCell>
      <TableCell align="right">{totalSaleConsumptionInUnit}</TableCell>
      <TableCell align="right">{unit}</TableCell>
      <TableCell align="right">{unfulfilled}</TableCell>
      <TableCell align="right">{fabricStock}</TableCell>
      <TableCell align="right">{vendorStock}</TableCell>
      <TableCell align="right">{totalStock}</TableCell>
      <TableCell align="right">
        <TextField
          size="small"
          variant="outlined"
          value={vendorPO}
          onChange={(e) => handleVendorPOChange(e.target.value)}
        />
      </TableCell>
      <TableCell align="right">{netStock}</TableCell>
      <TableCell align="right">
        <TextField
          size="small"
          variant="outlined"
          value={skuPO}
          onChange={(e) => handleSKUPOChange(e.target.value)}
        />
      </TableCell>
      <TableCell align="right">{totalRequired}</TableCell>
      <TableCell align="right">{daysOfStockRequired}</TableCell>
      <TableCell align="right">{daysOfStockAvailable}</TableCell>
      <TableCell
        align="right"
        onClick={() =>
          setSelectedSalesData({
            name,
            sales: row.perDaySalesData,
          })
        }
      >
        {
          <VisibilityIcon
            sx={{
              cursor: "pointer",
              color:
                selectedSalesData && selectedSalesData.name === name
                  ? "blue"
                  : "black",
            }}
          />
        }
      </TableCell>
    </TableRow>
  );
};

const FabricPOTable = ({ tableData, setMaterialWithPO }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedSalesData, setSelectedSalesData] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      const row = tableData[0];
      setSelectedSalesData({
        name: `${row.fabricName} ${row.colorName}`,
        sales: row.perDaySalesData,
      });
    }
  }, [tableData]);

  return (
    <div>
      <SalesModal selected={selectedSalesData} />
      <TableContainer sx={{ minWidth: 700, my: 4, px: 10 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">total sale consumption</TableCell>
              <TableCell align="right">Sale consumption(kg/m)</TableCell>
              <TableCell align="right">unit</TableCell>
              <TableCell align="right">unfulfilled</TableCell>
              <TableCell align="right">fabric stock</TableCell>
              <TableCell align="right">vendor stock</TableCell>
              <TableCell align="right">total stock</TableCell>
              <TableCell align="right">vendor po</TableCell>
              <TableCell align="right">net stock</TableCell>
              <TableCell align="right">sku po</TableCell>
              <TableCell align="right">total required</TableCell>
              <TableCell align="right">days of stock req</TableCell>
              <TableCell align="right">days of stock available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((row, i) => (
              <Row
                key={i}
                row={row}
                setMaterialWithPO={setMaterialWithPO}
                setSelectedSalesData={setSelectedSalesData}
                selectedSalesData={selectedSalesData}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FabricPOTable;
