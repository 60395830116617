import React, { useState, useEffect } from "react";
import { getOrdersInS3ByDate } from "../../actions/ordersActions";
import InvoicingTable from "../ordersWidgets/invoicing/InvoicingTable";
import { Button, Skeleton, Stack, TextField } from "@mui/material";
import { TextareaAutosize } from "@mui/base";

const CreateInvoice = () => {
    // Initially set for today
    const todayDate = new Date().toISOString().split("T")[0];
    const yesterdayDate = new Date(new Date(todayDate) - 1)
        .toISOString()
        .split("T")[0];
    const [showInput, setShowInput] = useState(false);
    const [s3Orders, setS3Orders] = useState([]);
    const [forwardOrders, setForwardOrders] = useState([]);
    const [date, setDate] = useState(todayDate);
    const [loading, setLoading] = useState(false);
    const [enteredAWBs, setEnteredAWBs] = useState("");

    const handleSearch = async () => {
        setLoading(true);
        const ordersInS3 = await getOrdersInS3ByDate(date);
        const invoiceData = ordersInS3.body?.map((o) => ({
            ...o,
            processed_at: new Date(date),
        }));
        setS3Orders(invoiceData);
        setForwardOrders(invoiceData);
        console.log("S3", invoiceData);
        setLoading(false);
    };

    useEffect(() => {
        handleSearch();
        return () => {
            // Cleanup function expected
        };
    }, [date]);

    const prepareTableWithAWB = () => {
        // prepare data
        const awbs = enteredAWBs.replace(/(\r\n|\n|\r)/gm, ",").split(",");
        console.log("AWBS entered", awbs);
        // Replace Table data with selected rows
        const selectedOrders = forwardOrders.filter((i) =>
            awbs.includes(i.awb_code)
        );
        setForwardOrders(selectedOrders);
        // CleanUp
        setEnteredAWBs("");
    };

    const resetForwardOrders = () => {
        setForwardOrders(s3Orders);
    };

    return (
        <div>
            <h1>CreateInvoice for Orders</h1>
            <br />
            {showInput ? (
                <Stack
                    direction={"row"}
                    gap={2}
                    my={2}
                    style={{ alignItems: "center" }}
                >
                    {forwardOrders.length === 0 ? (
                        <div>
                            <h2>Choose dispatch date</h2>
                            <Stack direction={"row"} gap={2} my={2}>
                                <Button
                                    variant={
                                        date === todayDate
                                            ? "contained"
                                            : "outlined"
                                    }
                                    color="error"
                                    onClick={() => setDate(todayDate)}
                                    disabled={loading}
                                    sx={{
                                        maxHeight: "50px",
                                    }}
                                >
                                    Today
                                </Button>
                                <Button
                                    variant={
                                        date === todayDate
                                            ? "outlined"
                                            : "contained"
                                    }
                                    color="error"
                                    onClick={() => setDate(yesterdayDate)}
                                    disabled={loading}
                                    sx={{
                                        maxHeight: "50px",
                                    }}
                                >
                                    Yesterday
                                </Button>
                            </Stack>
                        </div>
                    ) : (
                        <>
                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="Enter AWBs with comma"
                                value={enteredAWBs}
                                onChange={(e) => setEnteredAWBs(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                onClick={prepareTableWithAWB}
                                disabled={loading}
                                sx={{
                                    maxHeight: "50px",
                                }}
                            >
                                SUBMIT
                            </Button>
                        </>
                    )}
                    <h1>Or</h1>
                    <Button
                        variant="contained"
                        onClick={() => setShowInput(false)}
                        sx={{
                            maxHeight: "50px",
                        }}
                    >
                        Search By Date
                    </Button>
                </Stack>
            ) : (
                <Stack direction={"row"} gap={2} my={2}>
                    <TextField
                        type="date"
                        size="medium"
                        variant="standard"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        label="processed date"
                    />
                    <Button
                        variant="contained"
                        onClick={handleSearch}
                        disabled={loading}
                    >
                        Search
                    </Button>
                    <h1>Or</h1>
                    <Button
                        variant="contained"
                        onClick={() => setShowInput(true)}
                        disabled={loading}
                    >
                        Enter AWBs
                    </Button>
                </Stack>
            )}
            <div className="container">
                <div style={{ padding: "20px 10px" }}>
                    {loading ? (
                        <Skeleton
                            variant="rectangular"
                            width={910}
                            height={518}
                        />
                    ) : (
                        <>
                            {forwardOrders.length === 0 ? (
                                `No Orders found for ${date}`
                            ) : (
                                <InvoicingTable
                                    tableData={forwardOrders}
                                    resetTable={resetForwardOrders}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CreateInvoice;
