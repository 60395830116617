import React, { useState, useEffect } from "react";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TableFooter,
    TablePagination,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePaginationActions from "../../../utils/TablePaginationActions";
import {
    createInvoicePDF,
    createInvoicePDFBulk,
} from "../../../utils/pdfUtils";
import { delay } from "../../../utils/helpers";

function OrderActionsSelector({ orders, reset, removeFromTable }) {
    const printInvoicesSyncronously = async () => {
        let itemsToRemove = [];
        for (let i = 0; i < orders.length; i++) {
            //Print
            const filename = createInvoicePDF(orders[i]);
            await delay(1000);
            if (filename && filename !== "") {
                console.log("Removed", i, orders[i]?.fulfillment_id);
                itemsToRemove.push(orders[i]?.fulfillment_id);
            }
        }
        removeFromTable(itemsToRemove);
        reset([]);
    };

    const printInvoicesBulk = async () => {
        let itemsToRemove = [];

        //Print
        const filename = createInvoicePDFBulk(orders);

        if (filename && filename !== "") {
            console.log("Removed");
            itemsToRemove = orders.map((o) => o.fulfillment_id);
        }

        removeFromTable(itemsToRemove);
        reset([]);
    };

    const handleChange = async (e) => {
        if (e.target.value === 1) {
            printInvoicesSyncronously();
        } else if (e.target.value === 2) {
            printInvoicesBulk();
        } else {
            // delete
            console.log("Deselect");
            reset([]);
        }
    };

    return (
        <FormControl
            variant="outlined"
            size="small"
            sx={{ m: 2, minWidth: 120 }}
        >
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={""}
                onChange={handleChange}
            >
                <MenuItem value={1}>Print Invoice</MenuItem>
                <MenuItem value={2}>Print Invoice Bulk</MenuItem>
                <MenuItem value={3}>Deselect</MenuItem>
            </Select>
        </FormControl>
    );
}

export default function InvoicingTable({ tableData, resetTable }) {
    const [data, setData] = useState(
        tableData?.sort(
            (a, b) => Number(a.original_order_id) - Number(b.original_order_id)
        )
    );
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [open, setOpen] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (tableData.length > 0) {
            setOpen(Array(tableData.length).fill(false));
        }
        setData(
            [...tableData]
                ?.sort(
                    (a, b) =>
                        Number(a.original_order_id) -
                        Number(b.original_order_id)
                )
                .reverse()
        );
    }, [tableData]);

    const removeFromTable = (fulfillment_ids) => {
        const filtered = data.filter(
            (d) => !fulfillment_ids.includes(d.fulfillment_id)
        );
        console.log("Called for", fulfillment_ids, filtered.length);
        setData(filtered);
        // if empty table, reset everything
        if (filtered.length === 0) {
            resetTable();
        }
    };

    return (
        <>
            {data.length > 0 ? (
                <>
                    {selectedOrders.length > 0 ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <h4>Selected Action : </h4>
                            <OrderActionsSelector
                                orders={selectedOrders}
                                reset={setSelectedOrders}
                                removeFromTable={removeFromTable}
                            />
                        </div>
                    ) : (
                        <h4>Select orders to print invoice.</h4>
                    )}
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <p>Select</p>

                                        <div>
                                            <Checkbox
                                                color="primary"
                                                sx={{
                                                    margin: 0,
                                                }}
                                                checked={
                                                    selectedOrders.length ===
                                                    tableData.length
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedOrders(
                                                            tableData
                                                        );
                                                    } else {
                                                        setSelectedOrders([]);
                                                    }
                                                }}
                                            />
                                            <span>All</span>
                                        </div>
                                    </TableCell>
                                    <TableCell>Order Number</TableCell>
                                    <TableCell>AWB Code</TableCell>
                                    <TableCell>Processed At</TableCell>
                                    <TableCell>Payment</TableCell>
                                    <TableCell>Package Status</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Product</TableCell>
                                    <TableCell>List</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? data.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                      )
                                    : data
                                ).map((row, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow
                                            key={index}
                                            sx={{
                                                bgcolor:
                                                    index % 2 === 0
                                                        ? "rgba(145,145,145, 0.2)"
                                                        : "#edf5e1",

                                                "& > *": {
                                                    borderBottom: "unset",
                                                },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    checked={
                                                        selectedOrders.filter(
                                                            (i) =>
                                                                i.fulfillment_id ===
                                                                row.fulfillment_id
                                                        ).length > 0
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedOrders([
                                                                ...selectedOrders,
                                                                row,
                                                            ]);
                                                        } else {
                                                            setSelectedOrders(
                                                                selectedOrders.filter(
                                                                    (item) =>
                                                                        item.fulfillment_id !==
                                                                        row.fulfillment_id
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {row.original_order_id} (#
                                                {row.fulfillment_id})
                                            </TableCell>
                                            <TableCell>
                                                {row.awb_code
                                                    ? row.awb_code
                                                    : "No awb"}
                                            </TableCell>
                                            <TableCell>
                                                {new Date(
                                                    row.processed_at
                                                ).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell>{row.payment}</TableCell>
                                            <TableCell
                                                sx={{
                                                    fontWeight: "bold",
                                                    color: "blue",
                                                }}
                                            >
                                                {row.package_status}
                                            </TableCell>

                                            <TableCell>{row.total}</TableCell>
                                            <TableCell>
                                                {row.order_items?.length} Items
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => {
                                                        const temp = open.map(
                                                            (i, openIndex) => {
                                                                if (
                                                                    index !==
                                                                    openIndex
                                                                ) {
                                                                    return i;
                                                                } else {
                                                                    return !i;
                                                                }
                                                            }
                                                        );
                                                        setOpen(temp);
                                                    }}
                                                >
                                                    {open[index] ? (
                                                        <KeyboardArrowUpIcon />
                                                    ) : (
                                                        <KeyboardArrowDownIcon />
                                                    )}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                style={{
                                                    paddingBottom: 0,
                                                    paddingTop: 0,
                                                }}
                                                colSpan={6}
                                            >
                                                <Collapse
                                                    in={open[index]}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <Box sx={{ margin: 1 }}>
                                                        <Typography
                                                            variant="h6"
                                                            gutterBottom
                                                            component="div"
                                                        >
                                                            Product Details
                                                        </Typography>
                                                        <Table
                                                            size="small"
                                                            aria-label="purchases"
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Name
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        SKU
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Size
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Quantity
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        MRP
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Discount
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.order_items?.map(
                                                                    (
                                                                        productRow
                                                                    ) => (
                                                                        <TableRow
                                                                            key={
                                                                                productRow.sku
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    productRow.name
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    productRow.sku
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    productRow.variant_title
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    productRow.units
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    productRow.selling_price
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    productRow.discount
                                                                                }
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                        <h4>
                                                            Shipping Address:
                                                        </h4>
                                                        <p>
                                                            Name:{" "}
                                                            {row.billing_customer_name +
                                                                " " +
                                                                row.billing_last_name}
                                                            <br />
                                                            Mobile:{" "}
                                                            {row.shipping_phone}
                                                            <br />
                                                            Address:{" "}
                                                            {
                                                                row.shipping_address
                                                            }
                                                            <br />
                                                            {
                                                                row.shipping_address_2
                                                            }
                                                            <br />
                                                            {
                                                                row.shipping_city
                                                            }{" "}
                                                            |{" "}
                                                            {row.shipping_state}
                                                            <br />
                                                            {
                                                                row.shipping_country
                                                            }
                                                            <br />
                                                            {
                                                                row.shipping_pincode
                                                            }
                                                        </p>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}

                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{ height: 53 * emptyRows }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        style={{ overflow: "inherit" }}
                                        rowsPerPageOptions={[
                                            5,
                                            10,
                                            25,
                                            { label: "All", value: -1 },
                                        ]}
                                        colSpan={3}
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "rows per page",
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                            handleChangeRowsPerPage
                                        }
                                        ActionsComponent={
                                            TablePaginationActions
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                "No Under Process Orders At Present"
            )}
        </>
    );
}
