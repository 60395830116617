import { useState } from "react";
import React from "react";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSingleReverseOrderDetails } from "../../actions/reverseDeliveryActions";
import { showFullScreenLoader } from "../../actions/loaderActions";
import ProductViewModal from "./ReverseProductViewModal";
import { allInventory } from "../../actions/inventoryActions";
import { XbReverseShipmentHistoryModal } from "../ordersWidgets/returns/XbReverseShipmentHistoryModal";
import {
  getDelhiveryShipmentDetails,
  getXBReverseShipmentDetails,
} from "../../actions/ordersActions";
import { DelhiveryOrderHistory } from "../delhivery/tracking/DelhiveryOrderHistory";

const ReverseDetails = () => {
  const [searchId, setSearchId] = useState("");
  const [productModal, setProductModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);

  const { reverse_single } = useSelector((state) => state.reverseDetails);
  const { loading } = useSelector((state) => state.loader);
  const { stock } = useSelector((state) => state.inventoryDetails);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(showFullScreenLoader());
    dispatch(getSingleReverseOrderDetails({ id: searchId }));
    if (stock.length === 0) dispatch(allInventory());
  };

  const handleData = () => {
    let tempArr = [];
    tempArr.push(reverse_single[0]);
    setModalData(tempArr);
  };
  const handleOpenHistory = (awb) => {
    dispatch(getDelhiveryShipmentDetails(awb));
    setOrderHistoryModal(true);
  };
  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress size={100} color="inherit" />
        </Backdrop>
      )}
      <Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <TextField
            label="Search OrderId"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
            size="small"
            style={{ width: "300px", background: "white" }}
          />
          <Button
            disabled={searchId === ""}
            style={{ left: "10px" }}
            onClick={handleSubmit}
            variant="contained"
          >
            Search
          </Button>
        </Box>
        <br />
        {reverse_single.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Order ID</TableCell>
                  <TableCell align="left">Created On</TableCell>
                  <TableCell align="left">AWB</TableCell>
                  <TableCell align="left">Last Sync Status</TableCell>
                  <TableCell align="left">Customer Details</TableCell>
                  <TableCell align="left">Requested</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reverse_single.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.fulfillment_id}
                    </TableCell>
                    <TableCell align="left">
                      {new Date(row.sync_date).toLocaleDateString("en-in")}
                    </TableCell>
                    <TableCell align="left">
                      <Box>
                        <Typography>
                          {row.awb_code === ""
                            ? "N/A"
                            : !!row.awb_code
                            ? row.awb_code
                            : "N/A"}
                        </Typography>
                        <Typography
                          onClick={() => {
                            setProductModal(true);
                            handleData();
                          }}
                          style={{
                            fontSize: "x-small",
                            color: "blue",
                            cursor: "pointer",
                            borderBottom: "2px dotted",
                            width: "max-content",
                          }}
                        >
                          View Products
                        </Typography>
                        {productModal ? (
                          <ProductViewModal
                            productModal={productModal}
                            tableData={modalData}
                            setProductModal={setProductModal}
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {row.package_status === "new"
                        ? "New Order"
                        : String(row.package_status)
                            .replace("bsync#", "")
                            .replace("_", " ")}
                    </TableCell>
                    <TableCell style={{ maxWidth: "200px" }}>
                      <Typography style={{ color: "blue" }}>
                        {row.address ? row.address.name : ""}
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontSize: "small" }}
                      >
                        {row.address ? row.address.address1 : ""},
                        {row.address
                          ? `${row.address.city},${row.address.province}`
                          : ""}
                        ,{row.address ? row.address.zip : ""}
                        <br />
                        Ph : {row.address ? row.address.phone : ""}
                        <br />
                        Alt : {row.alternatePhone ? row.alternatePhone : ""}
                        <br />
                        Email : {row.email ? row.email : ""}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{row.exchangeAction}</TableCell>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={!row.awb_code || row.awb_code === ""}
                        onClick={() => handleOpenHistory(row.awb_code)}
                      >
                        More Details
                      </Button>
                      {orderHistoryModal ? (
                        <DelhiveryOrderHistory
                          open={orderHistoryModal}
                          setOrderHistoryModal={setOrderHistoryModal}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          "No Orders To Show"
        )}
      </Box>
    </>
  );
};

export default ReverseDetails;
