import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsGQL } from "../../actions/ordersActions";
import { allInventory } from "../../actions/inventoryActions";
import { getProcessedQuantity } from "../../actions/POActions";
import RedirectLogin from "../RedirectLogin";
import { Button, TextField, CircularProgress, Skeleton } from "@mui/material";
import { toast } from "react-toastify";

const OrderDispatchability = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const { processedQntyList } = useSelector((state) => state.PO);
    const { stock } = useSelector((state) => state.inventoryDetails);
    const [loading, setLoading] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [order, setOrder] = useState(null);
    const [estimate, setEstimate] = useState(null);
    const [predText, setPredText] = useState([]);

    useEffect(() => {
        if (!processedQntyList.length > 0) dispatch(getProcessedQuantity());
        if (!stock.length > 0) dispatch(allInventory());
    }, [dispatch, auth]);

    useEffect(() => {
        if (order) {
            // GET DETAILS
            let tempEstimateLineItems = JSON.parse(
                JSON.stringify(order.lineItems)
            );
            order.lineItems.forEach((l) => {
                const inventoryRecord = stock.find(
                    (p) => p.SKU === l.sku && p.size === l.variant?.title
                );
                const poRecord = processedQntyList.find(
                    (p) => p.barcode === l.sku
                );
                tempEstimateLineItems = tempEstimateLineItems.map((item) => {
                    if (
                        item.sku === l.sku &&
                        item.variant?.title === l.variant?.title
                    ) {
                        //UPDATE
                        item.count = inventoryRecord?.count || 0;
                        if (poRecord?.finishing[l.variant?.title] > 0) {
                            item.po = "finishing";
                        } else if (poRecord?.stitching[l.variant?.title] > 0) {
                            item.po = "stitching";
                        } else if (poRecord?.cutting[l.variant?.title] > 0) {
                            item.po = "cutting";
                        } else {
                            item.po = "Not found";
                        }
                    }
                    return item;
                });
            });
            const prediction = tempEstimateLineItems.reduce((acc, i) => {
                if (i.count > 0) {
                    acc.push(
                        `At least one product(${i.sku}) can be dispatched in 24 hours.`
                    );
                } else if (i.po === "finishing") {
                    acc.push(
                        `At least one product(${i.sku}) can be dispatched in 48 hours.`
                    );
                } else if (i.po === "stitching") {
                    acc.push(
                        `At least one product(${i.sku}) can be dispatched in 72 hours.`
                    );
                } else if (i.po === "cutting") {
                    acc.push(
                        `At least one product(${i.sku}) can be dispatched in 96 hours.`
                    );
                }
                return acc;
            }, []);
            setPredText(prediction);
            setEstimate(tempEstimateLineItems);
        }
    }, [order]);

    const formatOrderData = (order) => {
        const unfulfilledLineItems = order.lineItems.reduce((acc, item) => {
            let mismatch = 0;
            const validFulfillments = order.fulfillments.filter(
                (f) => f.status !== "CANCELLED"
            );
            const found = validFulfillments.find((f) => {
                const foundItems = f.fulfillmentLineItems.filter((i) => {
                    if (
                        i.lineItem.sku === item.sku &&
                        i.lineItem.variant.title === item.variant.title
                    ) {
                        mismatch = item.quantity - i.lineItem.quantity;
                        return true;
                    } else {
                        return false;
                    }
                });

                return foundItems.length > 0;
            });
            if (!found) {
                return [...acc, item];
            } else {
                if (mismatch > 0) {
                    const newLineItem = {
                        ...item,
                        quantity: mismatch,
                    };
                    return [...acc, newLineItem];
                } else {
                    return acc;
                }
            }
        }, []);

        return {
            ...order,
            lineItems: unfulfilledLineItems,
        };
    };

    const fetchOrderDetails = async () => {
        setOrder(null);
        try {
            setLoading(true);
            const result = await getOrderDetailsGQL(orderId);
            if (result) {
                // Format the data
                const formatted = formatOrderData(result);
                setOrder(formatted);
                setOrderId("");
            } else {
                toast("Enter Correct Data !");
            }
        } catch (error) {
            console.error("Error fetcing Data", error);
        }
        setLoading(false);
    };

    return (
        <>
            {!auth ? (
                <RedirectLogin />
            ) : (
                <>
                    <h1>Check Order Dispatchability</h1>
                    <br />
                    {stock.length === 0 || processedQntyList.length === 0 ? (
                        <div style={{ position: "relative" }}>
                            <Skeleton
                                animation="wave"
                                variant="rounded"
                                width={"100%"}
                                height={500}
                            ></Skeleton>
                            <h1
                                style={{
                                    position: "absolute",
                                    zIndex: 10,
                                    top: "200px",
                                    left: "0px",
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                Loading inventory and P.O. data. Please wait.
                            </h1>
                        </div>
                    ) : (
                        <>
                            <TextField
                                label={`Enter id`}
                                variant="outlined"
                                value={orderId}
                                onChange={(e) => setOrderId(e.target.value)}
                                style={{ marginBottom: "20px", minWidth: 200 }}
                            />
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={fetchOrderDetails}
                                >
                                    Search
                                </Button>
                            )}
                        </>
                    )}
                    {order && (
                        <>
                            <div style={{ display: "flex", gap: "20px" }}>
                                <h1 style={{ marginTop: "50px" }}>
                                    Order {order.name}
                                </h1>
                                <h2 style={{ marginTop: "50px" }}>
                                    Status: {order.displayFulfillmentStatus}
                                </h2>
                                <h3 style={{ marginTop: "50px" }}>
                                    Tags:{" "}
                                    {order.tags
                                        ? order.tags.map((i, k) => (
                                              <span key={k}>{i + ", "}</span>
                                          ))
                                        : "None"}
                                </h3>
                            </div>
                            <br />
                            <p>
                                Order Date:{" "}
                                {new Date(order.createdAt).toLocaleDateString(
                                    "en-IN"
                                ) +
                                    ", " +
                                    new Date(
                                        order.createdAt
                                    ).toLocaleTimeString("en-IN")}
                            </p>
                            <br />
                            {order.fulfillments?.length > 0 && (
                                <div>
                                    <h2>Past fulfillments</h2>
                                    {order.fulfillments.map((f, i) => (
                                        <div
                                            key={i}
                                            style={{
                                                display: "flex",
                                                gap: "10px",
                                                background:
                                                    f.status === "CANCELLED"
                                                        ? "pink"
                                                        : "white",
                                                padding: "5px",
                                                margin: "10px 0 10px 0",
                                                color:
                                                    f.status === "CANCELLED"
                                                        ? "red"
                                                        : "black",
                                            }}
                                        >
                                            {f.status === "CANCELLED" && (
                                                <h4>Status: {f.status}</h4>
                                            )}
                                            <h4>Name: {f.name}</h4>
                                            <h4>
                                                Date:{" "}
                                                {new Date(
                                                    f.createdAt
                                                ).toLocaleDateString("en-IN")}
                                            </h4>
                                            <div
                                                style={{
                                                    width: "250px",
                                                    background: "#f2f2f2",
                                                    padding: "5px",
                                                }}
                                            >
                                                <h4>Products:</h4>
                                                <ol>
                                                    {f.fulfillmentLineItems.map(
                                                        (i, k) => (
                                                            <li key={k}>
                                                                {i.lineItem
                                                                    .sku +
                                                                    ":" +
                                                                    i.lineItem
                                                                        .variant
                                                                        .title +
                                                                    ", count:" +
                                                                    i.lineItem
                                                                        .quantity}
                                                            </li>
                                                        )
                                                    )}
                                                </ol>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <br />
                            {order.lineItems.length > 0 && (
                                <>
                                    <br />
                                    <h2>Unfulfilled Items:</h2>
                                    <br />
                                    {predText.length === 0 ? (
                                        <h4>
                                            Products not available. Please wait
                                            7 days.
                                        </h4>
                                    ) : (
                                        <ul>
                                            {predText.map((text, i) => (
                                                <li key={i}>
                                                    <h4>{text}</h4>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    <br />
                                    {order.lineItems.map((p, i) => (
                                        <div
                                            key={i}
                                            style={{
                                                display: "flex",
                                                gap: "10px",
                                                background: "white",
                                                padding: "5px",
                                            }}
                                        >
                                            <img
                                                src={p.image?.url}
                                                alt="LBI"
                                                width="100"
                                            />
                                            <h4 style={{ width: "300px" }}>
                                                {p.title}
                                            </h4>
                                            <p style={{ width: "100px" }}>
                                                {p.sku}
                                            </p>
                                            <p style={{ width: "50px" }}>
                                                Size:{p.variant?.title}
                                            </p>
                                            <p
                                                style={{
                                                    width: "150px",
                                                    textAlign: "right",
                                                }}
                                            >
                                                Stock:{" "}
                                                {
                                                    estimate?.filter(
                                                        (item) =>
                                                            p.sku ===
                                                                item.sku &&
                                                            p.variant?.title ===
                                                                item.variant
                                                                    ?.title
                                                    )[0]?.count
                                                }
                                            </p>
                                            <p
                                                style={{
                                                    width: "150px",
                                                    textAlign: "right",
                                                }}
                                            >
                                                P.O.:{" "}
                                                {
                                                    estimate?.filter(
                                                        (item) =>
                                                            p.sku ===
                                                                item.sku &&
                                                            p.variant?.title ===
                                                                item.variant
                                                                    ?.title
                                                    )[0]?.po
                                                }
                                            </p>
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default OrderDispatchability;
