import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FabricPOTable from "./FabricPOTable";
import { getMaterialList } from "../../../actions/materialActions";
import FabricDataGenerate from "./FabricDataGenerate";

const FabricPOPerFabric = ({ vendorMaterials, unfulfilledOrders }) => {
  const [loading, setLoading] = useState(false);
  const [colors, setColors] = useState([]);

  const [selectedFabric, setSelectedFabric] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);

  const { materialFabricList: fabrics } = useSelector(
    (state) => state.inventoryDetails
  );

  useEffect(() => {
    if (fabrics.length > 0) {
      setSelectedFabric(fabrics[0].code);
    }
  }, [fabrics]);

  useEffect(() => {
    if (selectedFabric) {
      setColors([]);
      getMaterialList(selectedFabric).then((data) => {
        if (data.statusCode === 200) {
          const vendorColors = data.body.map((m) => ({
            code: m.colorId,
            name: m.colorName,
          }));
          if (vendorColors.length === 0) {
            toast.error("No colors found for this fabric");
          }
          vendorColors.unshift({
            code: "All",
            name: "All",
          });
          const vendorListAdd = data.body.map((b) => ({
            ...b,
            vendorNames: b.vendors.map((v) => v.name).join(" , "),
          }));
          setMaterials(vendorListAdd);
          setColors(vendorColors);
          setSelectedColor("All");
        }
      });
    }
  }, [selectedFabric]);

  useEffect(() => {
    if (selectedFabric) {
      const filtered = materials.filter(
        (m) =>
          m.fabricId === selectedFabric &&
          (selectedColor === "All" || m.colorId === selectedColor)
      );
      setFilteredMaterials(filtered);
      console.log(filtered);
    }
  }, [selectedFabric, selectedColor, materials]);

  console.log(
    "unfulfilled",
    unfulfilledOrders,
    filteredMaterials,
    vendorMaterials
  );
  return (
    <div>
      <Paper sx={{ p: 4 }}>
        {loading && (
          <Stack spacing={1} my={3}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

            <Skeleton variant="rectangular" width={500} height={60} />
            <Skeleton variant="rounded" width={500} height={60} />
          </Stack>
        )}
        {selectedFabric && (
          <Box>
            <Stack
              direction={"row"}
              gap={2}
              sx={{
                justifyContent: "center",
              }}
            >
              {/* fabric */}
              <TextField
                style={{ width: "250px" }}
                select
                label="Select Fabric"
                value={selectedFabric}
                onChange={(e) => setSelectedFabric(e.target.value)}
                variant="standard"
                className="select"
                // fullWidth
              >
                {fabrics.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {/* color */}
              <TextField
                style={{ width: "250px" }}
                disabled={colors.length === 0}
                select
                label="Select Color"
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
                variant="standard"
                className="select"
                // fullWidth
              >
                {colors.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Box>
        )}
        {filteredMaterials.length > 0 && (
          <FabricDataGenerate
            unfulfilledOrders={unfulfilledOrders}
            materialData={filteredMaterials}
            vendorMaterials={vendorMaterials}
          />
        )}
      </Paper>
    </div>
  );
};

export default FabricPOPerFabric;
