import { toast } from "react-toastify";
import { ClientId } from "../utils/UserPool";
import { showModal, showUnauthorizedModal } from "./errorModalActions";
import { boxes, courier_shopify } from "../utils/helperArrays";
import {
    getRandomNumberFromSeries,
    getRandomNumberOfSeconds,
    getXpressBeesOrderFormat,
    waitForSeconds,
} from "../utils/helpers";
import { lambdaFetch } from "../utils/awsUtils";
import { id } from "date-fns/locale";
import {
    showFailure,
    showSuccess,
    hideLoader,
    hideFullScreenLoader,
} from "./loaderActions";
import {
    sendPayloadToMail,
    sendREapprovedMessage,
    sendRErejectedMessage,
} from "./SESActions";
import { Email } from "@mui/icons-material";
import { dispatch } from "d3";
import { formatCurrency } from "../utils/orderTakeoutHelpers";

// const xpressBeesToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjQ4Njg0MyIsImNsaWVudGlkIjoyMDQ1Mywic2VjcmV0a2V5IjoiYjg3YThlYWUyZDBjYzY0ZTAzNmU0YjQ4MTVjOWJiZmViOWRiZTJiZmRlM2Q0NGE4NzNjYmRhNWIyMzc1OTRjMSIsImlhdCI6MTY2NDUxNDcwNywiZXhwIjoxNjY0NTE4MzA3fQ.09uIKrFObAPNu9p0NoYud-Aq3JGxJDlVpvDXsL2mdB4"

const lastAuthUser = localStorage.getItem(
    "CognitoIdentityServiceProvider." + ClientId + ".LastAuthUser"
);
const tokenName =
    "CognitoIdentityServiceProvider." +
    ClientId +
    "." +
    lastAuthUser +
    ".idToken";
const IDtoken = localStorage.getItem(tokenName);
const ShiprocketToken = localStorage.getItem("ShiprocketToken");

export const showLoading = () => {
    return {
        type: "SHOW_LOADER",
    };
};

export const hideLoading = () => {
    return {
        type: "HIDE_LOADER",
    };
};

export const showFLoading = () => {
    return {
        type: "SHOW_FULLFILLMENT_LOADER",
    };
};

export const hideFLoading = () => {
    return {
        type: "HIDE_FULLFILLMENT_LOADER",
    };
};

const updateTheOrderInDynamo = (order) => {
    return {
        type: "UPDATE_DYNAMO_SINGLE",
        payload: order,
    };
};

const deleteTheOrderInDynamo = (order) => {
    return {
        type: "DELETE_DYNAMO_SINGLE",
        payload: order,
    };
};

const deleteNonDeliveredOrder = (order) => {
    return {
        type: "DELETE_NON_DELIVERED_SINGLE",
        payload: order,
    };
};

export const allOrders = () => {
    const getAllOrders = async (IDtoken) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/myshopifyorders";
        let fetching = true;
        let orders = [];
        let nextUrl = null;
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",
                body: JSON.stringify({ currentUrl: nextUrl, pageLength: 250 }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            console.log(data.body);
            if (data.statusCode === 200) {
                orders.push(...data.body);
                nextUrl = data.next;
                if (!nextUrl) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    };
    return (dispatch) => {
        getAllOrders(IDtoken)
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "ALL_ORDERS",
                        data,
                    });
                } else {
                    // dispatch(showModal());
                    toast.error("Orders Not Found");
                }
            })
            .catch((error) => {
                dispatch(showModal());
                console.log("error", error);
                toast.error(
                    "Something Went Wrong With Fetching All Orders From Shopify",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const getProcessingOrdersData = () => {
    async function getProcessingOrders() {
        const ProcessingOrders_URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/packingorderfromprocessing";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(ProcessingOrders_URL, {
                method: "POST",

                body: JSON.stringify({ key }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();

            if (data.statusCode === 200) {
                const processingData = JSON.parse(data.body);
                orders.push(...processingData);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        dispatch(showLoading());
        getProcessingOrders()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    console.log("ALL", data.length);
                    dispatch({
                        type: "PROCESSING_ORDERS",
                        data,
                    });
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(showModal());
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const underProcessOrders = (orders, name = "xb") => {
    const putDispatchedOrders = async (orders) => {
        const putDispatchOrdersUrl =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/putintermediateorder";
        console.log("payload", { orders, name });
        const response = await lambdaFetch(putDispatchOrdersUrl, {
            method: "POST",

            body: JSON.stringify({ orders, name }),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        putDispatchedOrders(orders)
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(hideLoading());
                    toast.success("Saved Under Process Orders Successfully");
                } else {
                    dispatch(hideLoading());
                    dispatch(showModal());
                    console.log("ERROR", data);
                    toast.error("Error in Saving Proccessing Orders");
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                dispatch(showModal());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const getNDROrders = () => {
    const allNDROrders = async (IDtoken, ShiprocketToken) => {
        const putDispatchOrdersUrl =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/getndrshiprocket";

        const response = await lambdaFetch(putDispatchOrdersUrl, {
            method: "POST",

            body: JSON.stringify({ token: ShiprocketToken }),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        allNDROrders(IDtoken, ShiprocketToken)
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    const ndrOrders = data.body.data;
                    dispatch(hideLoading());
                    dispatch({
                        type: "ALL_NDR",
                        ndrOrders,
                    });
                    toast.success("All NDR Orders Loaded Successfully");
                } else {
                    dispatch(hideLoading());
                    toast.error("Error in Fetchinf NDR Orders");
                }
            })
            .catch((error) => {
                console.log("ERROR", error);
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const updateTrackingNumber = async (payload) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifyupdatetrackingnumber";
    //TODO GET LAST AWB and INCREMENT
    try {
        console.log("tracking payload", JSON.stringify(payload));
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        console.log("data", data);
        if (data.statusCode === 200) {
            toast.success("Tracking number updated");
        } else {
            toast.error("Tracking number not updated");
        }
    } catch (error) {
        toast.error("server error");
    }
};

export const generateShipRocketOrder = (order) => {
    console.log("SR PAYLOAD", order);

    const sendData = async (IDtoken, token, order) => {
        const putShipRocketOrder =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/wrapperapishiprocket";

        const response = await lambdaFetch(putShipRocketOrder, {
            method: "POST",

            body: JSON.stringify({ order, token }),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        sendData(IDtoken, ShiprocketToken, order)
            .then((data) => {
                console.log(data);
                dispatch(hideLoading());
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    console.log("SHIPROCKT SUCCESS", data);
                    toast.success(
                        "Order Processed Successfully. " + data.message
                    );
                    const courier_name = courier_shopify[data.body.courier_name]
                        ? courier_shopify[data.body.courier_name]
                        : data.body.courier_name;
                    const updateTrackingNumberPayload = {
                        orderId: parseInt(order.id),
                        lineItems: order.order_items,
                        tracking_number: data.body.awb_code,
                        tracking_urls: [
                            `https://shiprocket.co/tracking/${data.body.awb_code}`,
                        ],
                        tracking_company: courier_name, //add tracking company
                    };
                    console.log(updateTrackingNumberPayload);
                    updateTrackingNumber(updateTrackingNumberPayload);
                    dispatch(updateAWBForShopify(data.body));
                    // dispatch(updateTheOrderInDynamo(data.body));
                    // FulFIll in shopify
                    // dispatch(fulfillInShopify(data.body));
                } else if (data.statusCode === 201) {
                    console.log("SHIPROCKT MSG", data.body);
                    if (
                        data.body.payload.action === "Checking serviceability"
                    ) {
                        toast.success(data.payload.error_message);
                        dispatch(
                            getShipRocketOrderDetails(
                                order,
                                data.body.payload.shipment_id
                            )
                        );
                    } else {
                        toast.success("ShipRocket Error. " + data.message);
                    }
                } else {
                    console.log("ERROR HERE, SHIPROCKT", data);
                    toast.error("There was some Problem." + data.message);
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const getShipRocketOrderDetails = (order, shipmentId) => {
    const sendData = async (IDtoken, token, id, fulfillment_id) => {
        const getShipRocketOrder =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updateshipmentidshiprocket";

        const response = await lambdaFetch(getShipRocketOrder, {
            method: "POST",

            body: JSON.stringify({ fulfillment_id, id, token }),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        sendData(IDtoken, ShiprocketToken, shipmentId, order.fulfillment_id)
            .then((data) => {
                dispatch(hideLoading());
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    toast.success(
                        "Order No, Shipment No Updated Successfully."
                    );
                    dispatch(updateTheOrderInDynamo(data.body));
                } else {
                    console.log("ERROR HERE, SHIPROCKT", data.body);
                    toast.error("There was some Problem updating data.");
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                console.log("here", order, shipmentId);
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const updateBoxID = (fulfillment_id, box_id) => {
    const { length, breadth, height } = boxes.find((b) => b.id === box_id);
    const update = async () => {
        const updateOrdersUrl =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/resetboxidshiprocket";

        const response = await lambdaFetch(updateOrdersUrl, {
            method: "POST",

            body: JSON.stringify({
                fulfillment_id,
                box_id,
                length,
                breadth,
                height,
            }),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        update()
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(hideLoading());
                    dispatch(updateTheOrderInDynamo(data.body));
                    toast.success("Updated Box Successfully");
                } else {
                    console.log("Error", data);
                    dispatch(hideLoading());
                    dispatch(showModal());
                    toast.error("Error in Updating Box");
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                dispatch(showModal());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const updateWeightOfOrder = (fulfillment_id, weight) => {
    const updateWeight = async () => {
        const updateOrderUrl =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/resetweightshiprocket";

        const response = await lambdaFetch(updateOrderUrl, {
            method: "POST",

            body: JSON.stringify({ fulfillment_id, weight }),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        updateWeight()
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showLoading());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(hideLoading());
                    dispatch(updateTheOrderInDynamo(data.body));
                    toast.success("Updated Weight Successfully");
                } else {
                    dispatch(hideLoading());
                    dispatch(showModal());
                    toast.error("Error in Updating Weight");
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                dispatch(showModal());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const updateAWBOfOrder = (fulfillment_id, awb_code, reverse = true) => {
    console.log("HERE", fulfillment_id, awb_code);
    const payload = {
        fulfillment_id,
        awb_code,
        company: reverse ? "R_LBI" : "LBI",
    };
    const updateAWB = async () => {
        const updateOrderUrl =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/resetawb";

        const response = await lambdaFetch(updateOrderUrl, {
            method: "POST",

            body: JSON.stringify(payload),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        updateAWB()
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(hideLoading());
                    dispatch(updateTheOrderInDynamo(data.body));
                    toast.success("Updated AWB Successfully");
                } else {
                    dispatch(hideLoading());
                    dispatch(showModal());
                    toast.error("Error in Updating AWB");
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                dispatch(showModal());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const unfulfilledPartialOrders = (
    minDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0],
    maxDate = new Date().toISOString().split("T")[0]
) => {
    const getOrders = async () => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifyunfulfilledpartialorders";
        let fetching = true;
        let orders = [];
        let nextUrl = null;

        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",
                body: JSON.stringify({
                    currentUrl: nextUrl,
                    pageLength: 250,
                    minDate,
                    maxDate,
                }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            console.log(data);
            if (data.statusCode === 200) {
                orders.push(...data.body);
                nextUrl = data.next;
                if (!nextUrl) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    };
    return (dispatch) => {
        dispatch(showLoading());
        getOrders()
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                }
                if (data.length > 0) {
                    dispatch(hideLoading());
                    dispatch({
                        type: "UNFULFILLED_ORDERS",
                        data,
                    });
                } else {
                    dispatch(showModal());
                    toast.error("Orders Not Found");
                }
            })
            .catch((error) => {
                // dispatch(showModal());
                toast.error(error, {
                    theme: "colored",
                });
            });
    };
};

export const removeUnfullfilledOrders = () => {
    return {
        type: "REMOVE_UNFULFILLED_ORDERS",
    };
};

export const allUnfulfilledOrders = (minDate, maxDate) => {
    const getOrders = async (IDtoken) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifyunfulfilled";

        let fetching = true;
        let orders = [];
        let nextUrl = null;
        let count = 1;
        console.log("min date", minDate, "max date", maxDate);
        while (fetching) {
            console.log(`payload ${count}`, {
                maxDate,
                minDate,
                currentUrl: nextUrl,
                pageLength: 250,
            });
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify({
                    maxDate,
                    minDate,
                    currentUrl: nextUrl,
                    pageLength: 250,
                }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                orders.push(...data.body);
                console.log(`Page ${count}:`, data.body);
                nextUrl = data.next;
                count++;
                if (!nextUrl) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    };
    return (dispatch) => {
        getOrders(IDtoken)
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoader());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "ALL_UNFULFILLED_ORDERS",
                        data,
                    });
                    dispatch(hideLoader());
                } else {
                    dispatch(hideLoader());
                    dispatch(removeUnfullfilledOrders());
                    toast.error("No Orders Found To Fulfill For Given Dates");
                }
            })
            .catch((error) => {
                dispatch(hideLoader());
                toast.error(
                    "Something Went Wrong With Connecting To Shopify Server",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const getShopifyOrdersForBulkMail = (minDate, maxDate) => {
    const getOrders = async (IDtoken) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/bulkordermail";

        let fetching = true;
        let orders = [];
        let nextUrl = null;
        let count = 1;
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify({
                    maxDate,
                    minDate,
                    currentUrl: nextUrl,
                    pageLength: 250,
                }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                orders.push(...data.body);
                console.log(`Page ${count}:`, data.body);
                nextUrl = data.next;
                count++;
                if (!nextUrl) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    };
    return (dispatch) => {
        getOrders(IDtoken)
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoader());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "SHOPIFY_ORDER_LIST_FOR_MAIL",
                        data,
                    });
                    dispatch(hideLoader());
                } else {
                    dispatch(hideLoader());
                    toast.error("No Orders Found");
                }
            })
            .catch((error) => {
                dispatch(hideLoader());
                toast.error(
                    "Something Went Wrong With Connecting To Shopify Server",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const revertProcessing = (order) => {
    const deleteProcessingOrder = async (order) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/cancelprocessingorders";

        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify(order),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        deleteProcessingOrder(order)
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    toast.success("Cancelled Under Process Order Successfully");
                    dispatch(deleteTheOrderInDynamo(order));
                    console.log("Updated products", data.body);
                    dispatch(hideLoading());
                } else {
                    toast.error("Error in Cancelling Order");
                    console.log("Error", data.body);
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
                dispatch(hideLoading());
            });
    };
};

export const revertPNRProcessing = (order) => {
    const deleteProcessingOrder = async (order) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/packetnotready";

        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify(order),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        deleteProcessingOrder(order)
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    toast.success("Cancelled Under Process Order Successfully");
                    dispatch(deleteNonDeliveredOrder(order));
                    dispatch(hideLoading());
                } else {
                    toast.error("Error in Cancelling Order");
                    console.log("Error", data.body);
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
                dispatch(hideLoading());
            });
    };
};

export const getShiprocketToken = () => {
    const getToken = async () => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/getshiprockettokenkey";

        const response = await lambdaFetch(URL, {
            method: "POST",
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        getToken()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    const token_id = data.body;
                    toast.success("Shiprocket Token Added Succesfully");
                    localStorage.setItem("ShiprocketToken", token_id);
                } else {
                    toast.error("Error in Saving ShipRocket Token");
                }
            })
            .catch((error) => {
                console.log("error", error);
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const fulfillInShopify = (order) => {
    const payload = {
        order_id: order.id,
        location_id: order.location_id || "65807843485",
        line_items: order.order_items.map((o) => ({
            id: o.id,
            quantity: o.units,
        })),
        tracking_urls: [`https://shiprocket.co/tracking/${order.awb_code}`],
        tracking_number: order.awb_code,
    };

    const fulfill = async () => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updatefulfillmentstatus";

        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(payload),
            });
            if (response.status === 403) {
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                console.log("fulfillment", data);
                return {
                    success: true,
                    message: "Fulfilled",
                };
            } else {
                console.log("ERROR in fulfillment in shopify", data);
                return {
                    success: false,
                    message: "Could not fulfill",
                };
            }
        } catch (e) {
            console.log("ERROR lambda", e);
            return {
                success: false,
                message: "Could not fulfill",
            };
        }
    };
    return (dispatch) => {
        dispatch(showLoading());
        fulfill()
            .then((data) => {
                dispatch(hideLoading());
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.success) {
                    // UPDATE IN DYNAMODB STATUS: dispatched
                    dispatch(
                        markStatusDynamo(order.fulfillment_id, "dispatched")
                    );
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error(
                    "Something Went Wrong With Connecting To Shopify Server",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const revertFulfillment = (order, pnr) => async (dispatch) => {
    dispatch(showLoading());

    const payload = {
        order_id: order.id,
        tracking_number:
            order.awb_code === "changed" ? order.old_awb_code : order.awb_code,
    };

    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/revertfulfillmentstatus";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Cancelled Fulfillment in Shopify");
            console.log("Cancelled fulfillment", data.body);
            if (pnr) {
                // PACKET NOT READY
                dispatch(revertPNRProcessing(order));
            } else {
                dispatch(revertProcessing(order));
            }
        } else {
            console.log("ERROR in fulfillment in shopify", data);
            toast.error("Error in Cancelling Order");
            dispatch(hideLoading());
        }
    } catch (e) {
        console.log("ERROR lambda", e);
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const addNoteShopify = (order) => async (dispatch) => {
    dispatch(showLoading());

    const payload = {
        id: order.id,
        tracking_number: String(order.awb_code),
    };
    console.log(payload);

    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/addNoteShopify";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            dispatch(revertProcessing(order));
            toast.success("Order Tracking Removed & Tag Added In Shopify");
        } else {
            toast.error("Error in Closing Order");
            dispatch(hideLoading());
        }
    } catch (e) {
        console.log("ERROR lambda", e);
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const CancelAndPnrShopify = (order) => async (dispatch) => {
    dispatch(showLoading());

    const payload = {
        id: order.id,
        tracking_number: String(order.awb_code),
    };
    console.log(payload);

    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/addNoteShopify";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            dispatch(revertPNRProcessing(order));
            toast.success("Order Tracking Removed & Tag Added In Shopify");
        } else {
            toast.error("Error in Closing Order");
            dispatch(hideLoading());
        }
    } catch (e) {
        console.log("ERROR lambda", e);
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const markStatusDynamo = (fulfillment_id, status) => {
    const putStatusOfOrders = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/markdynamoorderstatus";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify([{ fulfillment_id, status }]),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        putStatusOfOrders()
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch({
                        type: "UPDATE_DYNAMO_SINGLE",
                        payload: data.body[0].fulfillment_id,
                    });
                    dispatch(hideLoading());
                    toast.success("Saved Status Successfully");
                } else {
                    dispatch(hideLoading());
                    toast.error("Error in Saving Status");
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const allShopifyInventory = () => {
    console.log("All shopify Inventory getting called");
    const getAllInventory = async (IDtoken) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/getshopifyinventory";
        let fetching = true;
        let products = [];
        let nextUrl = null;
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify({ currentUrl: nextUrl, pageLength: 250 }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                console.log("inventory body", data);
                if (!!data.body) {
                    products.push(...data.body);
                    nextUrl = data.next;
                    if (!nextUrl) {
                        fetching = false;
                    }
                } else {
                    const numberOfSeconds = getRandomNumberOfSeconds();
                    toast.error(
                        `Limit exceeded, retrying after ${numberOfSeconds} seconds`
                    );
                    await waitForSeconds(numberOfSeconds);
                }
            } else {
                products = [];
                fetching = false;
            }
        }
        return products;
    };

    return (dispatch) => {
        getAllInventory(IDtoken)
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "ALL_SHOPIFY_INVENTORY",
                        data,
                    });
                } else {
                    dispatch(showModal());
                    toast.error("Shopify Inventory Not Found");
                }
            })
            .catch((error) => {
                dispatch(showModal());
                console.log("error", error);
                toast.error(
                    "Something Went Wrong With Fetching All Inventory Products From Shopify",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const getDynamoDeliveredOrdersData = () => {
    async function getData() {
        const Delivered_URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/lbigetdeliveredorderfromprocessing";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(Delivered_URL, {
                method: "POST",

                body: JSON.stringify({ key }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                const processingData = JSON.parse(data.body);
                orders.push(...processingData);
                console.log("delivered orders", orders);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        dispatch(showLoading());
        getData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "DELIVERED_ORDERS",
                        data,
                    });
                } else {
                    toast.error("Error In Getting Delivered Orders");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const getShiprocketDispatchableOrders = () => {
    async function getData() {
        const Delivered_URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/LBIgetorderbystatus";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(Delivered_URL, {
                method: "POST",

                body: JSON.stringify({ key, status: "sync#dispatched" }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            console.log("INTRANSIT", data);
            if (data.statusCode === 200) {
                const processingData = data.body;
                orders.push(...processingData);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        getData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "INTRANSIT_ORDERS",
                        data,
                    });
                } else {
                    toast.error("Error In Getting In Transit Orders");
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const getDynamoNonDeliveredOrdersData = () => {
    async function getData() {
        const NotDelivered_URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/lbigetnotdeliveredorderfromprocessing";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(NotDelivered_URL, {
                method: "POST",

                body: JSON.stringify({ key }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                const processingData = JSON.parse(data.body);
                orders.push(...processingData);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        dispatch(showLoading());
        getData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    dispatch(hideLoading());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "NON_DELIVERED_ORDERS",
                        data,
                    });
                } else {
                    toast.error("Error In Getting Non Delivered Orders");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const getShiprocketSyncData = () => {
    async function getData() {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/lbigetnotdeliveredorderfromprocessing";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify({ key, shiprocket_sync: true }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                const processingData = JSON.parse(data.body);
                orders.push(...processingData);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        dispatch(showLoading());
        getData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    dispatch(hideLoading());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "NON_DELIVERED_ORDERS",
                        data,
                    });
                } else {
                    toast.error("Error In Getting Non Delivered Orders");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const getAllShipRocketOrderDelivered = () => {
    const getData = async () => {
        const todayDate = new Date().toISOString().split("T")[0];
        const lastWeekDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .split("T")[0];
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/getallshiprocketdelivered";
        //  https://apiv2.shiprocket.in/v1/external/orders?to=2022-08-20&from=2022-08-01&filter_by="status"&filter="DELIVERED"&per_page=200`;
        let fetching = true;
        let orders = [];
        let nextPage = 1;
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify({
                    token: ShiprocketToken,
                    from: "2024-09-01", //lastWeekDate, //"2022-07-05",
                    to: todayDate,
                    page: nextPage,
                }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();

            if (data.statusCode === 200) {
                console.log("PAGE:", nextPage, data);
                orders.push(...data.body);
                if (!data.meta.pagination.links.next) {
                    fetching = false;
                    console.log("STOPPED LOADING");
                }
                nextPage++;
            } else {
                // Some other error
                console.log("ERROR", data);
                orders = [];
                fetching = false;
            }
        }
        // const clientFilteredOrders = orders.filter(
        //   (o) => o.status === "DELIVERED"
        //   // "RTO IN TRANSIT" ||
        //   // o.status === "RTO INITIATED" ||
        //   // o.status === "RTO_OFD"

        //   //"IN TRANSIT" || o.status === "IN TRANSIT-EN-ROUTE"
        //   // "RTO DELIVERED"
        //   // "DELIVERED"  "RTO_OFD" //"RTO INITIATED" //"IN TRANSIT-EN-ROUTE",
        // );
        const clientFilteredOrders = orders;
        console.log("ALL from SR", orders.length);
        console.log("ACTUALLY delivered", clientFilteredOrders.length);
        return clientFilteredOrders;
    };
    return (dispatch) => {
        dispatch(showLoading());
        getData()
            .then((orders) => {
                dispatch(hideLoading());
                if (orders === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (orders.length > 0) {
                    console.log(orders);
                    toast.success("Fetched Successfully.");
                    dispatch({
                        type: "SR_DELIVERED_ORDERS",
                        payload: orders,
                    });
                } else {
                    console.log("ERROR HERE, SHIPROCKET", orders);
                    toast.error("There was some Problem getting data.");
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                console.log("here", error);
                toast.error(
                    "Something Went Wrong With Connecting To Shiprocket",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const markDeliveredBulk = (orders) => {
    const payload = orders.map((o) => ({
        fulfillment_id: o.fulfillment_id,
        delivery_charges: o.delivery_charges,
    }));
    const putStatusOfOrders = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/changedynamoorderstatus";

        console.log("HERE", orders);
        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify(payload),
        });
        console.log("HERE", response);
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        putStatusOfOrders()
            .then((data) => {
                console.log("DATA", data);
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(hideLoading());
                    toast.success("Saved Status Successfully");
                    // Clear SR delivered orders
                    dispatch({
                        type: "CLEAR_SR_DELIVERED_ORDERS",
                    });
                    // Clear non delivered ordersReducer
                    dispatch({
                        type: "CLEAR_NON_DELIVERED_ORDERS",
                    });
                } else {
                    dispatch(hideLoading());
                    console.log("ERROR", data);
                    toast.error("Error in Saving Status");
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const getSingleShipRocketOrderDetails = (id) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/getorderdetailsshiprocket";

    dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify({
                token: ShiprocketToken,
                order_id: id,
            }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        dispatch(hideLoading());
        if (data === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        if (data.statusCode === 200) {
            dispatch({
                type: "UPDATE_NON_DELIVERED_ORDERS",
                payload: data.body.data,
            });
            toast.success("Fetched Successfully.");
            console.log(data.body.data);
        } else {
            toast.error(JSON.parse(data.body), {
                theme: "colored",
            });
        }
    } catch (err) {
        dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Shiprocket", {
            theme: "colored",
        });
    }
};

export const replenishRTOorder = (id) => async (dispatch) => {
    // console.log(id)
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/replenishrtoorder";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(id),
        });
        const data = await response.json();
        // console.log(data)
        if (data.statusCode === 200) {
            dispatch({
                type: "UPDATE_RTO_STOCK",
                payload: id,
            });
            dispatch(hideFullScreenLoader());
            toast.success("Stock Added In Inventory Successfully");
        } else {
            toast.error("Error in Adding Stock In Inventory", {
                theme: "colored",
            });
            console.log(data);
            dispatch(hideFullScreenLoader());
        }
    } catch (err) {
        dispatch(hideLoader());
        console.log("here", err);
        toast.error("Something Went Wrong", {
            theme: "colored",
        });
    }
};

export const replenishReverseorder =
    (id, package_status) => async (dispatch) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/replenishReverseOrder";
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify({ id }),
            });
            if (response.status === 403) {
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                if (package_status === "dispatched") {
                    dispatch({
                        type: "UPDATE_DISPATCHED_REVERSE_STOCK",
                        payload: id,
                    });
                } else if (package_status === "dispatchedReverse") {
                    dispatch({
                        type: "UPDATE_SYNC_DISPATCHED_REVERSE_STOCK",
                        payload: id,
                    });
                } else {
                    dispatch({
                        type: "UPDATE_DELIVERED_REVERSE_STOCK",
                        payload: id,
                    });
                }
                dispatch(hideLoader());
                toast.success("Stock Added In Inventory Successfully");
            } else {
                toast.error("Error in Adding Stock In Inventory", {
                    theme: "colored",
                });
                console.log(data);
                dispatch(hideLoader());
            }
        } catch (err) {
            dispatch(hideLoader());
            console.log("here", err);
            toast.error("Something Went Wrong With Connecting To Database", {
                theme: "colored",
            });
        }
    };

export const updateDetailsOfOrder = (orderBody) => {
    const updateOrder = async () => {
        const updateOrderUrl =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updateDynamoOrderDetails";

        const response = await lambdaFetch(updateOrderUrl, {
            method: "POST",

            body: JSON.stringify(orderBody),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        updateOrder()
            .then((data) => {
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(hideLoading());
                    dispatch(updateTheOrderInDynamo(data.body));
                    toast.success("Updated Order Details Successfully");
                } else {
                    dispatch(hideLoading());
                    dispatch(showModal());
                    toast.error("Error in Updating Order Details");
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                dispatch(showModal());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const fulfillBulkInShopify = (orders) => {
    const erroredOrders = [];
    const fulfill = async (order, fulfillment_id) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updatefulfillmentstatus";
        console.log("order", order);
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(order),
            });
            if (response.status === 403) {
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                return {
                    success: true,
                    message: `Fulfilled AWB ${order.tracking_number}`,
                };
            } else {
                erroredOrders.push(
                    `${order.tracking_number}(${fulfillment_id})`
                );
                return {
                    success: false,
                    message: `Shopify Error of AWB ${order.tracking_number}`,
                };
            }
        } catch (e) {
            return {
                success: false,
                message: "Could not fulfill. Lambda error",
            };
        }
    };
    return async (dispatch) => {
        dispatch(showFLoading());
        let count = 0;
        while (count < orders.length) {
            console.log("count", count, orders.length);
            const payload = {
                order_id: orders[count].id,
                // location_id: orders[0][i].location_id || "65807843485",
                line_items: orders[count].order_items.map((o) => ({
                    id: o.id,
                    quantity: o.units,
                })),
                tracking_urls: [
                    `https://www.xpressbees.com/track?trackid=${orders[count].awb_code}`,
                ],
                tracking_number: parseInt(orders[count].awb_code),
                shipping_partner: "XpressBees",
            };
            try {
                const data = await fulfill(
                    payload,
                    orders[count].fulfillment_id
                );
                if (data === 403) {
                    console.log("Errored", erroredOrders);
                    dispatch(showUnauthorizedModal());
                    dispatch(hideFLoading());
                    return;
                }
                if (data.success) {
                    // UPDATE IN DYNAMODB STATUS: dispatched
                    dispatch(
                        markStatusDynamo(
                            orders[count].fulfillment_id,
                            "sync#dispatched"
                        )
                    );
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.log("Bulk Fulfillment ERROR", error);

                toast.error(
                    "Something Went Wrong With Connecting To Shopify Server",
                    {
                        theme: "colored",
                    }
                );
            }
            count++;
            if (count >= orders.length) {
                console.log("Errored awb", erroredOrders);
                //todo email
                dispatch(
                    sendPayloadToMail({
                        name: "error in fulfillment",
                        awb: erroredOrders,
                    })
                );
                dispatch(hideFLoading());
            }
        }
    };
};

export const fulfillBulkInShopifyDelhivery = (orders) => {
    const erroredOrders = [];
    const fulfill = async (order, fulfillment_id) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updatefulfillmentstatus";
        console.log("order", order);
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(order),
            });
            if (response.status === 403) {
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                return {
                    success: true,
                    message: `Fulfilled AWB ${order.tracking_number}`,
                };
            } else {
                erroredOrders.push(
                    `${order.tracking_number}(${fulfillment_id})`
                );
                return {
                    success: false,
                    message: `Shopify Error of AWB ${order.tracking_number}`,
                };
            }
        } catch (e) {
            return {
                success: false,
                message: "Could not fulfill. Lambda error",
            };
        }
    };
    return async (dispatch) => {
        dispatch(showFLoading());
        let count = 0;
        while (count < orders.length) {
            console.log("count", count, orders.length);
            const payload = {
                order_id: orders[count].id,
                // location_id: orders[0][i].location_id || "65807843485",
                line_items: orders[count].order_items.map((o) => ({
                    id: o.id,
                    quantity: o.units,
                })),
                tracking_urls: [
                    `https://www.delhivery.com/track/package/${orders[count].awb_code}`,
                ],
                tracking_number: parseInt(orders[count].awb_code),
                shipping_partner: "Delhivery",
            };
            try {
                const data = await fulfill(
                    payload,
                    orders[count].fulfillment_id
                );
                if (data === 403) {
                    console.log("Errored", erroredOrders);
                    dispatch(showUnauthorizedModal());
                    dispatch(hideFLoading());
                    return;
                }
                if (data.success) {
                    // UPDATE IN DYNAMODB STATUS: dispatched
                    dispatch(
                        markStatusDynamo(
                            orders[count].fulfillment_id,
                            "sync#dispatched"
                        )
                    );
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.log("Bulk Fulfillment ERROR", error);

                toast.error(
                    "Something Went Wrong With Connecting To Shopify Server",
                    {
                        theme: "colored",
                    }
                );
            }
            count++;
            if (count >= orders.length) {
                console.log("Errored awb", erroredOrders);
                //todo email
                dispatch(
                    sendPayloadToMail({
                        name: "error in fulfillment",
                        awb: erroredOrders,
                    })
                );
                dispatch(hideFLoading());
            }
        }
    };
};

export const fulfillBulkInShopifyWithoutTrackingUpdate = (orders) => {
    const erroredOrders = [];
    const fulfill = async (order) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updatefulfillmentstatus";

        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(order),
            });
            if (response.status === 403) {
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                return {
                    success: true,
                    message: `Fulfilled AWB ${order.tracking_number}`,
                };
            } else {
                erroredOrders.push(order.tracking_number);
                return {
                    success: false,
                    message: `Shopify Error of AWB ${order.tracking_number}`,
                };
            }
        } catch (e) {
            return {
                success: false,
                message: "Could not fulfill. Lambda error",
            };
        }
    };
    return async (dispatch) => {
        dispatch(showFLoading());
        let count = 0;
        while (count < orders.length) {
            const payload = {
                order_id: orders[count].id,
                line_items: orders[count].order_items.map((o) => ({
                    id: o.id,
                    quantity: o.units,
                })),
            };
            try {
                const data = await fulfill(payload);
                if (data === 403) {
                    console.log("Errored", erroredOrders);
                    dispatch(showUnauthorizedModal());
                    dispatch(hideFLoading());
                    return;
                }
                if (data.success) {
                    // UPDATE IN DYNAMODB STATUS: dispatched
                    // dispatch(
                    //   markStatusDynamo(orders[count].fulfillment_id, "sync#dispatched")
                    // );
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.log("Bulk Fulfillment ERROR", error);

                toast.error(
                    "Something Went Wrong With Connecting To Shopify Server",
                    {
                        theme: "colored",
                    }
                );
            }
            count++;
            if (count >= orders.length) {
                console.log("Errored awb", erroredOrders);
                dispatch(hideFLoading());
            }
        }
    };
};

export const updateLastXBReverseAWB = (awbNo) => {
    const updateReverseAwb = async () => {
        let awb;
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxupdatelastawbnumber";
        // try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({}),
        });

        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        updateReverseAwb()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch({
                        type: "REVERSE_AWB_NO",
                        payload: (parseInt(awbNo) + 1).toString(),
                    });
                    toast.success("Reverse Awb Updated Successfully");
                } else {
                    toast.error("Could Not Update Reverse AWB No");
                }
            })
            .catch((error) => {
                toast.error(error);
            });
    };
};

export const getLastXBReverseAWB = async () => {
    let awb;
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxgetawbnumber";
    //TODO GET LAST AWB and INCREMENT
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({}),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        console.log("AWB new", data);
        awb = data.awb;
        return awb;
    } catch (error) {
        return false;
    }
};

export const postXBReverseOrder = (productBody, email) => {
    const reverseOrder = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/xbpostreverseorder";
        const response = await lambdaFetch(Url, {
            method: "POST",
            body: JSON.stringify(productBody),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        console.log("reverse response", data);
        return data;
    };
    return (dispatch) => {
        dispatch(showLoading);
        reverseOrder()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(showSuccess());
                    console.log("success reverse", data.body);
                    // toast.success("Reverse Order Generated in XpressBees Successfully");
                    if (email) {
                        dispatch(sendREapprovedMessage(email));
                    } else {
                        toast("Email not provided");
                    }
                    dispatch(getNewReturnExchangeOrders());

                    toast.success("approved successfully");
                } else {
                    console.log("Failed", data.body);
                    toast.error(data.body);
                    dispatch(showFailure());
                    dispatch(hideLoading());
                }
            })
            .catch((error) => {
                console.log("reverse", error);
                dispatch(showFailure());
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
        dispatch(hideLoading());
    };
};

export const postXBForwardSingleOrder = (productBody) => {
    const forwardOrder = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/xbpostsingleorder";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify(productBody),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading);
        forwardOrder()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    console.log("success forward", data.body);
                    toast.success(
                        "Forward Order Generated in XpressBees Successfully"
                    );
                } else {
                    console.log("failed forward order", data.body);
                    toast.error(data.body.ReturnMessage);
                }
            })
            .catch((error) => {
                console.log("forward", error);
                toast.error("Something Went Wrong. Please Reload Again", {
                    theme: "colored",
                });
            });
    };
};

export const getNewReturnExchangeOrders = () => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifygetreturnexchangebystatus";
    dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify({
                status: "new",
            }),
        });
        if (response.status === 403) {
            dispatch(hideLoading());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        dispatch(hideLoading());
        if (data.statusCode === 200) {
            dispatch({
                type: "RETURN_EXCHANGE_ORDERS",
                payload: data.data,
            });
            toast.success("Fetched R/E Orders Successfully.");
        } else {
            toast.error(`Server Error ${data.statusCode}`, {
                theme: "colored",
            });
        }
    } catch (err) {
        dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const getNewReturnExchangeOrdersByExecutiveId =
    () => async (dispatch, getState) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifygetreturnexchangebyexecutiveidandstatus";
        dispatch(showLoading());
        const state = getState();
        const user = state.auth.user;
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify({
                    status: "new",
                    company: "R_LBI",
                    name: user,
                }),
            });
            if (response.status === 403) {
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            console.log("data", data);

            dispatch(hideLoading());
            if (data.statusCode === 200) {
                dispatch({
                    type: "RETURN_EXCHANGE_ORDERS",
                    payload: data.data,
                });

                toast.success("Fetched RE Orders Successfully.");
            } else {
                toast.error(`Server Error ${data.statusCode}`, {
                    theme: "colored",
                });
            }
        } catch (err) {
            dispatch(hideLoading());
            console.log("here", err);
            toast.error("Something Went Wrong With Connecting To Database", {
                theme: "colored",
            });
        }
    };

export const getcustomercareusernames = () => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/users/getusers";
    // dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify({}),
        });
        if (response.status === 403) {
            // dispatch(hideLoading());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        // dispatch(hideLoading());
        if (data.statusCode === 200) {
            dispatch({
                type: "SHOW_CUSTOMER_CARE_USERS",
                payload: data.data,
            });
            toast.success("Fetched  Users");
        } else {
            toast.error(`Server Error ${data.statusCode}`, {
                theme: "colored",
            });
        }
    } catch (err) {
        // dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const getRejectedReturnExchangeOrders = () => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifygetreturnexchangebystatus";
    dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({
                status: "rejected",
            }),
        });
        if (response.status === 403) {
            dispatch(hideLoading());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        dispatch(hideLoading());
        if (data.statusCode === 200) {
            dispatch({
                type: "REJECTED_RE_ORDERS",
                payload: data.data,
            });
            toast.success("Fetched Rejected R/E Orders Successfully.");
        } else {
            toast.error(`Server Error ${data.statusCode}`, {
                theme: "colored",
            });
        }
    } catch (err) {
        dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const getDispatchedReturnExchangeOrders = () => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifygetreturnexchangebystatus";
    // dispatch(showLoading());
    let payload = {
        status: "dispatched",
        LastEvaluatedKey: null,
    };
    let fetching = true;
    let allOrders = [];
    try {
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(payload),
            });
            if (response.status === 403) {
                fetching = false;
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                const orders = data.data;
                allOrders.push(...orders);
                payload.LastEvaluatedKey = data.LastEvaluatedKey;
                if (!payload.LastEvaluatedKey) {
                    fetching = false;
                }
                toast.success("Fetched Dispatched R/E Orders Successfully.");
            } else {
                fetching = false;
                allOrders = [];
                toast.error(`Server Error ${data.statusCode}`, {
                    theme: "colored",
                });
            }
        }
        dispatch({
            type: "DISPATCHED_RE_ORDERS",
            payload: allOrders,
        });
        // dispatch(hideLoading());
    } catch (err) {
        // dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const getsyncedDispatchedReturnExchangeOrders =
    () => async (dispatch) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifygetreturnexchangebystatus";
        // dispatch(showLoading());
        let payload = {
            status: "dispatchedReverse",
            LastEvaluatedKey: null,
        };
        let fetching = true;
        let allOrders = [];
        try {
            while (fetching) {
                const response = await lambdaFetch(URL, {
                    method: "POST",

                    body: JSON.stringify(payload),
                });
                if (response.status === 403) {
                    fetching = false;
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                const data = await response.json();
                if (data.statusCode === 200) {
                    const orders = data.data;
                    allOrders.push(...orders);
                    payload.LastEvaluatedKey = data.LastEvaluatedKey;
                    if (!payload.LastEvaluatedKey) {
                        fetching = false;
                    }
                    toast.success(
                        "Fetched Synced Dispatched R/E Orders Successfully."
                    );
                } else {
                    fetching = false;
                    allOrders = [];
                    toast.error(`Server Error ${data.statusCode}`, {
                        theme: "colored",
                    });
                }
            }
            dispatch({
                type: "SYNCED_DISPATCHED_RE_ORDERS",
                payload: allOrders,
            });
            // dispatch(hideLoading());
        } catch (err) {
            // dispatch(hideLoading());
            console.log("here", err);
            toast.error("Something Went Wrong With Connecting To Database", {
                theme: "colored",
            });
        }
    };

export const getDeliveredReturnExchangeOrders = () => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifygetreturnexchangebystatus";
    // dispatch(showLoading());
    let payload = {
        status: "deliveredReverse",
        LastEvaluatedKey: null,
    };
    let fetching = true;
    let allOrders = [];
    try {
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(payload),
            });
            if (response.status === 403) {
                fetching = false;
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                const orders = data.data;
                allOrders.push(...orders);
                payload.LastEvaluatedKey = data.LastEvaluatedKey;
                if (!payload.LastEvaluatedKey) {
                    fetching = false;
                }
                toast.success("Fetched Delivered R/E Orders Successfully.");
            } else {
                fetching = false;
                allOrders = [];
                toast.error(`Server Error ${data.statusCode}`, {
                    theme: "colored",
                });
            }
        }
        dispatch({
            type: "DELIVERED_RE_ORDERS",
            payload: allOrders,
        });
        // dispatch(hideLoading());
    } catch (err) {
        // dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const getNprReturnExchangeOrders = () => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifygetreturnexchangebystatus";
    // dispatch(showLoading());
    let payload = {
        status: "bsync#RPPickupPending",
        LastEvaluatedKey: null,
    };
    let fetching = true;
    let allOrders = [];
    try {
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(payload),
            });
            if (response.status === 403) {
                fetching = false;
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                const orders = data.data;
                allOrders.push(...orders);
                payload.LastEvaluatedKey = data.LastEvaluatedKey;
                if (!payload.LastEvaluatedKey) {
                    fetching = false;
                }
                toast.success("Fetched NPR R/E Orders Successfully.");
            } else {
                fetching = false;
                allOrders = [];
                toast.error(`Server Error ${data.statusCode}`, {
                    theme: "colored",
                });
            }
        }
        dispatch({
            type: "NPR_RE_ORDERS",
            payload: allOrders,
        });
        // dispatch(hideLoading());
    } catch (err) {
        // dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const getAllReturnExchangeOrders = () => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifygetreturnexchangebystatus";
    dispatch(showLoading());
    let payload = {
        status: "All",
        LastEvaluatedKey: null,
    };
    let fetching = true;
    let allOrders = [];
    try {
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(payload),
            });
            if (response.status === 403) {
                fetching = false;
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                const orders = data.data;

                allOrders.push(...orders);
                payload.LastEvaluatedKey = data.LastEvaluatedKey;
                if (!payload.LastEvaluatedKey) {
                    fetching = false;
                }
                toast.success("Fetched Dispatched R/E Orders Successfully.");
            } else {
                fetching = false;
                allOrders = [];
                toast.error(`Server Error ${data.statusCode}`, {
                    theme: "colored",
                });
            }
        }

        dispatch({
            type: "ALL_RE_ORDERS",
            payload: allOrders,
        });
        dispatch(hideLoading());
    } catch (err) {
        dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const clearNdrOrders = () => {
    return {
        type: "CLEAR_NDR_ORDERS",
    };
};

export const changeExecutiveIdNdr = (payload) => async (dispatch) => {
    const todayDate = new Date();
    const changeDateFormat = (date) => {
        const reqDate = (date.getDate() <= 9 ? "0" : "") + date.getDate();
        const reqMonth =
            (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1);
        const reqFormat = `${[date.getFullYear(), reqMonth, reqDate].join(
            "-"
        )}T00:00:00+05:30`;
        return reqFormat;
    };

    const firstDayLastMonth = changeDateFormat(
        new Date(todayDate.getFullYear(), todayDate.getMonth() - 2, 1)
    );
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/changeexecutiveid";

    // dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify(payload),
        });
        const data = await response.json();
        console.log(data);
        if (data.statusCode === 200) {
            toast.success("Task Successful");
            dispatch(clearNdrOrders());
            dispatch(
                getDynamoNdrOrdersData({
                    p_status: ["sync#UD"],
                    from_date: firstDayLastMonth,
                })
            );
            dispatch(hideFullScreenLoader());
        } else {
            // toast.error("Task Failed");
            dispatch(clearNdrOrders());
            dispatch(
                getDynamoNdrOrdersData({
                    p_status: ["sync#UD"],
                    from_date: firstDayLastMonth,
                })
            );
        }

        //update the new Orders with changes executive Id
        // dispatch(hideLoading());
    } catch (err) {
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
        dispatch(hideFullScreenLoader());
    }
};

export const getAssignedNdrOrders = () => async (dispatch, getState) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/shopifygetreturnexchangebyexecutiveidandstatus";
    dispatch(showLoading());
    const state = getState();
    const user = state.auth.user;
    let payload = {
        LastEvaluatedKey: null,
        status: "sync#UD",
        company: "LBI",
        name: user,
    };
    let fetching = true;
    let allOrders = [];

    try {
        while (fetching) {
            console.log("payload", payload);
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(payload),
            });
            if (response.status === 403) {
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            console.log("data", data);

            if (data.statusCode === 200) {
                allOrders.push(...data.data);
                payload.LastEvaluatedKey = data.LastEvaluatedKey;
                if (!payload.LastEvaluatedKey) {
                    fetching = false;
                }
            } else {
                fetching = false;
                allOrders = [];
                toast.error(`Server Error ${data.statusCode}`, {
                    theme: "colored",
                });
            }
        }
        console.log("all orders", allOrders);
        dispatch({
            type: "XB_NDR_ORDERS",
            data: allOrders,
        });
        dispatch(hideLoading());
        toast.success("Fetched NDR Orders Successfully.");
    } catch (err) {
        dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const changeExecutiveId = (payload) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/changeexecutiveid";

    dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify(payload),
        });
        if (response.status === 403) {
            dispatch(hideLoading());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        console.log("data", data);
        if (data.statusCode === 200) {
            toast.success("Task Successful");
        } else {
            toast.error("Task Failed");
        }

        dispatch(getNewReturnExchangeOrders());
        dispatch(hideLoading());
    } catch (err) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const updateREstatusPostXBReverse = (payload) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxupdatereverseorderstatus";
    dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({
                fulfillmentId: payload.OrderNo,
                status: "dispatched",
            }),
        });
        if (response.status === 403) {
            dispatch(hideLoading());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            // MAKE rebverse order XB
            // dispatch(postXBReverseOrder(payload));
            // UPDATE the awb_code in dynamoDB
            dispatch(updateAWBOfOrder(payload.OrderNo, payload.AirWayBillNO));
            // Update RE orders list
            dispatch(getNewReturnExchangeOrders());
            dispatch(updateLastXBReverseAWB(payload.AirWayBillNO));
            // toast.success("Approved R/E Order Successfully.");
        } else {
            dispatch(hideLoading());
            toast.error(`Server Error ${data.statusCode}`, {
                theme: "colored",
            });
        }
    } catch (err) {
        dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const denyRErequest = (payload, email) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxupdatereverseorderstatus";
    dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({
                fulfillmentId: payload.id,
                status: "rejected",
                rejection_reason: payload.reason,
            }),
        });
        if (response.status === 403) {
            dispatch(hideLoading());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            dispatch(getNewReturnExchangeOrders());
            toast.success("Rejected R/E Order Successfully.");
            if (email) {
                dispatch(sendRErejectedMessage(email));
            } else {
                toast("Email not provided");
            }
        } else {
            dispatch(hideLoading());
            toast.error(`Server Error ${data.statusCode}`, {
                theme: "colored",
            });
        }
    } catch (err) {
        dispatch(hideLoading());
        console.error("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const denyNprRErequest = (payload, email) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxupdatereverseorderstatus";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({
                fulfillmentId: payload.id,
                status: "rejected",
                rejection_reason: payload.reason,
            }),
        });
        if (response.status === 403) {
            dispatch(hideLoading());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            dispatch(getNprReturnExchangeOrders());
            toast.success("Rejected R/E Order Successfully.");
            // send mail that customer was not available
            // if (email) {
            //   dispatch(sendRErejectedMessage(email));
            // } else {
            //   toast("Email not provided");
            // }
            dispatch(hideLoader());
        } else {
            dispatch(hideLoader());
            toast.error(`Server Error ${data.statusCode}`, {
                theme: "colored",
            });
        }
    } catch (err) {
        dispatch(hideLoader());
        console.error("here", err);
        toast.error("Something Went Wrong.", {
            theme: "colored",
        });
    }
};

export const reinstateRErequest = (id) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxupdatereverseorderstatus";
    dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({
                fulfillmentId: id,
                status: "new",
            }),
        });
        if (response.status === 403) {
            dispatch(hideLoading());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            dispatch(getRejectedReturnExchangeOrders());
            toast.success("Reinstated R/E Order Successfully.");
        } else {
            dispatch(hideLoading());
            toast.error(`Server Error ${data.statusCode}`, {
                theme: "colored",
            });
        }
    } catch (err) {
        dispatch(hideLoading());
        console.error("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const closeRErequest = (id) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxupdatereverseorderstatus";
    dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({
                fulfillmentId: id,
                status: "closed",
            }),
        });
        if (response.status === 403) {
            dispatch(hideLoading());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            dispatch(getRejectedReturnExchangeOrders());
            toast.success("Closed R/E Order Successfully.");
        } else {
            dispatch(hideLoading());
            toast.error(`Server Error ${data.statusCode}`, {
                theme: "colored",
            });
        }
    } catch (err) {
        dispatch(hideLoading());
        console.error("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const getDynamoNdrOrdersData = (orderBody) => {
    async function getData(reqBody) {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/reverseorders/get_orders";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",
                body: JSON.stringify({ ...reqBody, key }),
            });

            const data = await response.json();
            if (data.statusCode === 200) {
                const processingData = data.body;
                orders.push(...processingData);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        // dispatch(showLoading());
        for (let i = 0; i < orderBody.p_status.length; i++) {
            const payload = {
                package_status: orderBody.p_status[i],
                from_date: orderBody.from_date,
            };

            getData(payload)
                .then((data) => {
                    // dispatch(hideLoading());
                    if (data) {
                        dispatch({
                            type: "XB_NDR_ORDERS",
                            data,
                        });
                        dispatch(hideFullScreenLoader());
                    } else {
                        console.log("Error in Fetching Data");
                        dispatch(hideFullScreenLoader());
                    }
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(hideFullScreenLoader());
                });
        }
    };
};

export const updateNdrOrder = (orderBody) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/LBIupdateNdrOrder";
    dispatch(showLoading());
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(orderBody),
        });
        const data = await response.json();

        if (data.statusCode === 200) {
            dispatch({
                type: "UPDATE_XB_NDR_ORDERS",
                payload: data.body,
            });
            toast.success("Order Status Changed Successfully");
        } else {
            toast.error("Error in Updating On Hold Order");
        }
    } catch (err) {
        console.error("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const updateNprOrder = (orderBody) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/LBIupdateNprOrder";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(orderBody),
        });
        if (response.status === 403) {
            dispatch(hideLoading());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            dispatch({
                type: "UPDATE_XB_NPR_ORDERS",
                payload: orderBody.fulfillment_id,
            });
            dispatch(hideLoader());
            toast.success("Order Status Changed Successfully");
        } else {
            dispatch(hideLoader());
            toast.error("Error in Updating Order");
        }
    } catch (err) {
        dispatch(hideLoader());
        console.error("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const getDynamoNdrOnholdOrdersData = () => {
    async function getData() {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/LBIgetNdronHoldOrders";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(Url, {
                method: "POST",

                body: JSON.stringify({ key }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                const processingData = JSON.parse(data.body);
                orders.push(...processingData);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        dispatch(showLoading());
        getData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "XB_NDR_ONHOLD_ORDERS",
                        data,
                    });
                    dispatch(hideLoading());
                } else {
                    toast.error("Error In Getting Ndr Orders");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const getCustomerDetails = (orderBody) => {
    async function getCustomerData() {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/ShopifyGetCustomerDetails";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify(orderBody),
        });

        const data = await response.json();
        console.log(data);
        return data;
    }
    return (dispatch) => {
        getCustomerData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch({
                        type: "SHOPIFY_CUSTOMER_DATA",
                        payload: data.body,
                    });
                } else if (data.statusCode === 404) {
                    dispatch({
                        type: "SHOPIFY_CUSTOMER_DATA",
                        payload: "",
                    });
                    toast.error(
                        "Customer Details Could Not Be Found. Please Check in Shopify"
                    );
                }
            })
            .catch((error) => {
                toast.error(
                    "Customer Details Could Not Be Found. Please Check in Shopify",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const removeCustomerDetails = () => {
    return {
        type: "REMOVE_CUSTOMER_DETAILS",
    };
};

export const getPincodes = () => {
    async function readPincodes() {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/readpincodes";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({}),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        console.log(data);
        return data;
    }
    return (dispatch) => {
        dispatch(showLoading());
        readPincodes()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    toast("Pincode fetched successfully");
                    dispatch({
                        type: "PINCODES",
                        payload: data.body,
                    });
                } else {
                    toast.error("Pincodes coudln't be fetch");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error(
                    "Something went wrong with connecting to database",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const getPincodesDelhivery = () => {
    console.log("HERE");
    async function readPincodes() {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/delhivery/getservicablepincodes";

        console.log("HERE2");

        const response = await lambdaFetch(Url, {
            method: "POST",
        });
        if (response.status === 403) {
            console.log("HERE4", response);
            return 403;
        }
        console.log("HERE3", response);
        const data = await response.json();

        console.log(data);
        return data;
    }
    return (dispatch) => {
        dispatch(showLoading());
        readPincodes()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    toast("Pincode fetched successfully");
                    dispatch({
                        type: "PINCODES_DELHIVERY",
                        payload: data.body,
                    });
                } else {
                    toast.error("Pincodes coudln't be fetch");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                console.log(error);
                toast.error(
                    "Something went wrong with connecting to database",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const updatePincodes = (pincodeArray) => {
    console.log("updating");
    async function updatePincode() {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updatepincodes";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ pincodes: pincodeArray }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        console.log(data);
        return data;
    }
    return (dispatch) => {
        dispatch(showLoading());
        updatePincode()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    toast("Pincode updated successfully");
                    dispatch(getPincodes());
                } else {
                    toast.error("Pincodes coudln't be fetch");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error(
                    "Something went wrong with connecting to database",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const updateOrderForShopify = (order) => {
    const awb = order.awb_code;
    const newOrder = {
        ...order,
        old_awb_code: awb,
        awb_code: "changed",
    };
    order.awb_code = "changed";
    async function updateOrder() {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updateshiprocketorder";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ newOrder }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        console.log(data);

        return data;
    }
    return (dispatch, getState) => {
        const state = getState();
        const { inTransitOrders, orderTakeout } = state.orderDetails;
        dispatch(showLoading());
        updateOrder()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    toast("Order Updated successfully");
                    const newOrder = data.body;
                    const newInTransitOrders = inTransitOrders.map((o) =>
                        o.fulfillment_id === newOrder.fulfillment_id
                            ? newOrder
                            : o
                    );
                    const newOrderTakeOut = orderTakeout.map((o) =>
                        o.fulfillment_id === newOrder.fulfillment_id
                            ? newOrder
                            : o
                    );
                    dispatch({
                        type: "INTRANSIT_ORDERS",
                        data: newInTransitOrders,
                    });
                    dispatch({
                        type: "ORDER_TAKEOUT",
                        payload: newOrderTakeOut,
                    });
                } else {
                    toast.error("Update Not Successful");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error(
                    "Something went wrong with connecting to database",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const updateAWBForShopify = (order) => {
    async function updateOrder() {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updateshiprocketorder";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ order }),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();

        return data;
    }
    return (dispatch, getState) => {
        const state = getState();
        const { inTransitOrders } = state.orderDetails;
        dispatch(showLoading());
        updateOrder()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    toast("Order Updated successfully");
                    const newOrder = data.body;
                    const newInTransitOrders = inTransitOrders.map((o) =>
                        o.fulfillment_id === newOrder.fulfillment_id
                            ? newOrder
                            : o
                    );
                    dispatch({
                        type: "INTRANSIT_ORDERS",
                        data: newInTransitOrders,
                    });
                } else {
                    toast.error("Update Not Successful");
                }
                dispatch(hideLoading());
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error(
                    "Something went wrong with connecting to database",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const getXBShipmentDetails = (orderBody) => {
    async function getXBShipmentData() {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/XBGetShipmentDetails";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ orderBody, reverse: false }),
        });

        const data = await response.json();
        // console.log(data);
        return data;
    }
    return (dispatch) => {
        getXBShipmentData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch({
                        type: "XB_ORDER_HISTORY",
                        payload: data.body,
                    });
                } else if (data.statusCode === 404) {
                    dispatch({
                        type: "XB_ORDER_HISTORY",
                        payload: "",
                    });
                    toast.error("Shipment Details for Given AWB not Found");
                }
            })
            .catch((error) => {
                toast.error(
                    "Shipment Details Error in Loading.Please Try Again",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const removeXBShipmentDetails = () => {
    return {
        type: "XB_REMOVE_ORDER_HISTORY",
    };
};

export const getXBReverseShipmentDetails = (orderBody) => {
    async function getXBShipmentData() {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/XBGetShipmentDetails";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ orderBody, reverse: true }),
        });

        const data = await response.json();
        // console.log(data);
        return data;
    }
    return (dispatch) => {
        getXBShipmentData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch({
                        type: "XB_REVERSE_ORDER_HISTORY",
                        payload: data.body,
                    });
                } else if (data.statusCode === 404) {
                    dispatch({
                        type: "XB_REVERSE_ORDER_HISTORY",
                        payload: "",
                    });
                    toast.error(
                        "Reverse Shipment Details for Given AWB not Found"
                    );
                }
            })
            .catch((error) => {
                toast.error(
                    "Reverse Shipment Details Error in Loading.Please Try Again",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const removeReverseXBShipmentDetails = () => {
    return {
        type: "XB_REVERSE_REMOVE_ORDER_HISTORY",
    };
};

export const XbNdrPostRequest = (XBObj, dynamoObj) => {
    const ndrRequest = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/XBNdrRequest";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify(XBObj),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        ndrRequest()
            .then((data) => {
                console.log("DATA", data.body);
                if (data.statusCode === 200) {
                    dispatch(updateNdrOrder(dynamoObj));
                    toast.success("Changed Status Successfully");
                    dispatch(hideFullScreenLoader());
                } else {
                    console.log("ERROR", data.body.ReturnMessage);
                    toast.error(data.body.ReturnMessage);
                    dispatch(hideFullScreenLoader());
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const XbNprPostRequest = (XBObj, dynamoObj) => {
    const nprRequest = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/XBNprRequest";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify(XBObj),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        nprRequest()
            .then((data) => {
                console.log("DATA", data.body);
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(updateNprOrder(dynamoObj));
                    // dispatch({
                    //   type: "UPDATE_DYNAMO_MULTIPLE",
                    //   payload: data.body,
                    // });
                    toast.success("Changed Status Successfully");
                } else {
                    dispatch(hideLoader());
                    console.log("ERROR", data.body.ReturnMessage);
                    toast.error(data.body.ReturnMessage);
                }
            })
            .catch((error) => {
                dispatch(hideLoader());
                toast.error("Something Went Wrong", {
                    theme: "colored",
                });
            });
    };
};

export const XbRtoPostRequest = (XBObj, dynamoObj) => {
    const rtoRequest = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/XBRtoRequest";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify(XBObj),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        dispatch(showLoading());
        rtoRequest()
            .then((data) => {
                console.log("DATA", data.body);
                if (data === 403) {
                    dispatch(hideLoading());
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(updateNdrOrder(dynamoObj));
                    // dispatch({
                    //   type: "UPDATE_DYNAMO_MULTIPLE",
                    //   payload: data.body,
                    // });
                    dispatch(hideLoading());
                    toast.success("Rto Placed Successfully");
                } else {
                    dispatch(hideLoading());
                    console.log("ERROR", data.body.ReturnMessage);
                    toast.error(data.body.ReturnMessage);
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const getDeliveredRTOrders = (orderBody) => {
    console.log(orderBody);
    async function getData(reqBody) {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/reverseorders/get_orders";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",
                body: JSON.stringify({ ...reqBody, key }),
            });

            const data = await response.json();
            if (data.statusCode === 200) {
                const processingData = data.body;
                orders.push(...processingData);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        // dispatch(showLoading());
        for (let i = 0; i < orderBody.p_status.length; i++) {
            const payload = {
                package_status: orderBody.p_status[i],
                from_date: orderBody.from_date,
            };

            getData(payload)
                .then((data) => {
                    // dispatch(hideLoading());
                    if (data) {
                        dispatch({
                            type: "DELIVERED_RTO_ORDERS",
                            data,
                        });
                    } else {
                        console.log("Error in Fetching Data");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
};

export const getDispatchedRTOrders = () => {
    async function getData() {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/LBIgetorderbystatus";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify({ key, status: "sync#RTO-IT" }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            // console.log("INTRANSIT", data);
            if (data.statusCode === 200) {
                const processingData = data.body;
                orders.push(...processingData);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        getData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "DISPATCHED_RTO_ORDERS",
                        data,
                    });
                } else {
                    toast.error("Error In Getting In RTO In Transit Orders");
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const updateRtoDeliveredOrders = (orderBody, orderId) => {
    const updateRTO = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/updateShopifyInventory";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify(orderBody),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        updateRTO()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(replenishRTOorder(orderId));
                } else {
                    console.log(data.body);
                    toast.error("Error In Updating Inventory In Shopify");
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const updateReverseDeliveredOrders = (orderBody, id, package_status) => {
    const updateRTO = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/updateShopifyInventory";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify(orderBody),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        updateRTO()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(replenishReverseorder(id, package_status));
                } else {
                    console.log(data.body);
                    toast.error("Error In Updating Inventory In Shopify");
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const updateDamagedRtoOrders = (orderBody, toBeUpdatedBody) => {
    const updateRTO = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/updateShopifyInventory";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify(orderBody),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        updateRTO()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(replenishDamagedRTOorder(toBeUpdatedBody));
                    toast.success("Added to shopify Inventory");
                } else {
                    toast.error("Error In Updating Inventory In Shopify");
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const updateDamagedReverseOrders = (
    orderBody,
    toBeUpdatedBody,
    package_status
) => {
    const updateRTO = async () => {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/updateShopifyInventory";

        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify(orderBody),
        });
        if (response.status === 403) {
            return 403;
        }
        return await response.json();
    };
    return (dispatch) => {
        updateRTO()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch(
                        replenishDamagedReverseOrder(
                            toBeUpdatedBody,
                            package_status
                        )
                    );
                    toast.success("Added to shopify Inventory");
                } else {
                    toast.error("Error In Updating Inventory In Shopify");
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const replenishDamagedRTOorder = (orderBody) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/replenishDamagedRto";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(orderBody),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            dispatch({
                type: "UPDATE_RTO_STOCK",
                payload: parseFloat(orderBody.id),
            });
            dispatch(hideFullScreenLoader());
            toast.success("Updated Successfully");
        } else {
            toast.error("Error in Adding Stock In Inventory", {
                theme: "colored",
            });
            console.log(data);
            dispatch(hideFullScreenLoader());
        }
    } catch (err) {
        dispatch(hideFullScreenLoader());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const replenishDamagedReverseOrder =
    (orderBody, package_status) => async (dispatch) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/replenishDamagedReverse";
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(orderBody),
            });
            if (response.status === 403) {
                dispatch(hideLoader());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                if (package_status === "dispatched") {
                    dispatch({
                        type: "UPDATE_DISPATCHED_REVERSE_STOCK",
                        payload: orderBody.id,
                    });
                } else if (package_status === "dispatchedReverse") {
                    dispatch({
                        type: "UPDATE_SYNC_DISPATCHED_REVERSE_STOCK",
                        payload: orderBody.id,
                    });
                } else {
                    dispatch({
                        type: "UPDATE_DELIVERED_REVERSE_STOCK",
                        payload: orderBody.id,
                    });
                }
                dispatch(hideLoader());
                toast.success("Updated Successfully");
            } else {
                toast.error("Error in Adding Stock In Inventory", {
                    theme: "colored",
                });
                console.log(data);
                dispatch(hideLoader());
            }
        } catch (err) {
            dispatch(hideLoader());
            console.log("here", err);
            toast.error("Something Went Wrong With Connecting To Database", {
                theme: "colored",
            });
        }
    };

export const createSingleOrderShopify =
    (orderBody, id, package_status) => async (dispatch) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/createshopifyorder";
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(orderBody),
            });
            if (response.status === 403) {
                dispatch(hideLoader());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                console.log(data.data);
                const responseData = data.data;
                const objBody = {
                    id: id,
                    fulfillment_id: responseData.order_number,
                    orderId: responseData.id,
                };
                dispatch(updateNewReverseOrder(objBody, package_status));
                toast.success("Order Created In Shopify Successfully");
            } else {
                toast.error("Error in Creating New Order", {
                    theme: "colored",
                });
                console.log(data);
                dispatch(hideLoader());
            }
        } catch (err) {
            dispatch(hideLoader());
            console.log("here", err);
            toast.error("Something Went Wrong", {
                theme: "colored",
            });
        }
    };

export const createSingleDraftOrderShopify =
    (orderBody, id, package_status) => async (dispatch) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/ShopifycreateDraftOrder";
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(orderBody),
            });
            if (response.status === 403) {
                dispatch(hideLoader());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                console.log(data.data);
                const responseData = data.data;
                const objBody = {
                    id: id,
                    fulfillment_id: responseData.name,
                    orderId: responseData.id,
                    draft: true,
                };
                dispatch(updateNewReverseOrder(objBody, package_status));
                toast.success("Draft Order Created In Shopify Successfully");
            } else {
                toast.error("Error in Creating New Draft Order", {
                    theme: "colored",
                });
                console.log(data);
                dispatch(hideLoader());
            }
        } catch (err) {
            dispatch(hideLoader());
            console.log("here", err);
            toast.error("Something Went Wrong", {
                theme: "colored",
            });
        }
    };

export const convertDraftToActiveOrderShopify =
    (orderBody, id, package_status) => async (dispatch) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/convertDraftOrderActive";
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(orderBody),
            });
            if (response.status === 403) {
                dispatch(hideLoader());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                console.log("convert", data);
                const responseData = data.body;
                const objBody = {
                    id: id,
                    fulfillment_id:
                        responseData.customer.last_order_name.replace("#", ""),
                    orderId: responseData.customer.last_order_id,
                };
                dispatch(updateNewReverseOrder(objBody, package_status));
                toast.success("Order Created In Shopify Successfully");
            } else {
                toast.error("Error in Creating New Order", {
                    theme: "colored",
                });
                console.log(data);
                dispatch(hideLoader());
            }
        } catch (err) {
            dispatch(hideLoader());
            console.log("here", err);
            toast.error("Something Went Wrong", {
                theme: "colored",
            });
        }
    };

export const sendDraftOrderInvoice = (orderBody) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/sendDraftOrderInvoice";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify(orderBody),
        });
        if (response.status === 403) {
            dispatch(hideLoader());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            dispatch(hideLoader());
            toast.success("Draft Order Invoice Sent Successfully");
        } else {
            toast.error("Error in Sending Draft Order Invoice", {
                theme: "colored",
            });
            console.log(data);
            dispatch(hideLoader());
        }
    } catch (err) {
        dispatch(hideLoader());
        console.log("here", err);
        toast.error("Something Went Wrong", {
            theme: "colored",
        });
    }
};

export const updateNewReverseOrder =
    (orderBody, package_status) => async (dispatch) => {
        const URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/updateNewReverseOrder";
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(orderBody),
            });
            if (response.status === 403) {
                dispatch(hideLoader());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                if (package_status === "dispatched") {
                    dispatch(getDispatchedReturnExchangeOrders());
                } else if (package_status === "dispatchedReverse") {
                    dispatch(getsyncedDispatchedReturnExchangeOrders());
                } else {
                    dispatch(getDeliveredReturnExchangeOrders());
                }
                dispatch(hideLoader());
                toast.success("Reverse Order Updated Successfully");
            } else {
                toast.error("Error in Updating Reverse Order", {
                    theme: "colored",
                });
                console.log(data);
                dispatch(hideLoader());
            }
        } catch (err) {
            dispatch(hideLoader());
            console.log("here", err);
            toast.error("Something Went Wrong", {
                theme: "colored",
            });
        }
    };

export const reApproveReverseOrder = (orderBody) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/approveNewReversePickup";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify(orderBody),
        });
        if (response.status === 403) {
            dispatch(hideLoader());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            dispatch(getNprReturnExchangeOrders());
            dispatch(hideLoader());
            toast.success("Order Updated Successfully");
        } else {
            toast.error("Error in Updating Order", {
                theme: "colored",
            });
            console.log(data);
            dispatch(hideLoader());
        }
    } catch (err) {
        dispatch(hideLoader());
        console.log("here", err);
        toast.error("Something Went Wrong", {
            theme: "colored",
        });
    }
};

export const getForwardSyncOrders = () => {
    async function getData() {
        const Delivered_URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/LBIgetorderbystatus";

        let fetching = true;
        let orders = [];
        let key = null;
        while (fetching) {
            const response = await lambdaFetch(Delivered_URL, {
                method: "POST",

                body: JSON.stringify({ key, status: "sync" }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            console.log("INTRANSIT", data);
            if (data.statusCode === 200) {
                const processingData = data.body;
                orders.push(...processingData);
                key = data.key;
                if (!key) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        fetching = true;
        let LastEvaluatedKey = null;
        console.log("RTO delivered");
        while (fetching) {
            const response = await lambdaFetch(
                "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/statusbydate",
                {
                    method: "POST",

                    body: JSON.stringify({ LastEvaluatedKey, status: "RTD" }),
                }
            );
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            console.log("INTRANSIT", data);
            if (data.statusCode === 200) {
                const processingData = data.body;
                orders.push(...processingData);
                LastEvaluatedKey = data.LastEvaluatedKey;
                console.log("last evaluated key", LastEvaluatedKey);
                if (!LastEvaluatedKey) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        fetching = true;
        LastEvaluatedKey = null;
        console.log("Delhivery");
        while (fetching) {
            const response = await lambdaFetch(
                "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/statusbydate",
                {
                    method: "POST",

                    body: JSON.stringify({ LastEvaluatedKey, status: "RTO" }),
                }
            );
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            console.log("Delhivery RT", data);
            if (data.statusCode === 200) {
                const processingData = data.body;
                orders.push(...processingData);
                LastEvaluatedKey = data.LastEvaluatedKey;
                console.log("last evaluated key", LastEvaluatedKey);
                if (!LastEvaluatedKey) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
            }
        }
        return orders;
    }
    return (dispatch) => {
        getData()
            .then((data) => {
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.length > 0) {
                    dispatch({
                        type: "INTRANSIT_ORDERS",
                        data,
                    });
                } else {
                    toast.error("Error In Getting Forward Sync Orders");
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong With Connecting To Server", {
                    theme: "colored",
                });
            });
    };
};

export const getWeeklySalesData = () => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/28";

    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            dispatch({
                type: "WEEKLY_SALES_DATA",
                payload: data.body,
            });
        } else {
            toast.error(`Error with Satus Code ${data.statusCode}`, {
                theme: "colored",
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const getTridiurnalSalesData = () => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/15";

    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            dispatch({
                type: "TRIDIURNAL_SALES_DATA",
                payload: data.body,
            });
        } else {
            toast.error(`Error with Satus Code ${data.statusCode}`, {
                theme: "colored",
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const getThreeMonthsSalesData = () => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/90";

    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            dispatch({
                type: "THREE_MONTHS_SALES_DATA",
                payload: data.body,
            });
        } else {
            toast.error(`Error with Satus Code ${data.statusCode}`, {
                theme: "colored",
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const getThreeUnfulfilledProductsData = () => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/9090";

    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            dispatch({
                type: "THREE_MONTHS_UNFULFILLED_PRODUCTS_DATA",
                payload: data.body,
            });
        } else {
            toast.error(`Error with Satus Code ${data.statusCode}`, {
                theme: "colored",
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const getPerDaySalesData = () => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/10";

    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            dispatch({
                type: "PER_DAY_SALES_DATA",
                payload: data.body,
            });
        } else {
            toast.error(`Error with Satus Code ${data.statusCode}`, {
                theme: "colored",
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const getThreeUnfulfilledProductsFilteredData =
    () => async (dispatch) => {
        dispatch(showLoading());
        const allURL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/9091";
        const noneURL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/9092";
        const zaamoURL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/9093";
        const wishlinkURL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/9094";
        const gapURL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/9095";
        const shopifyURL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/9096";

        try {
            const [resAll, resNone, resZaamo, resWish, resGap, resShop] =
                await Promise.all([
                    fetch(allURL),
                    fetch(noneURL),
                    fetch(zaamoURL),
                    fetch(wishlinkURL),
                    fetch(gapURL),
                    fetch(shopifyURL),
                ]);
            if (resAll.status === 403) {
                dispatch(showUnauthorizedModal());
                return;
            }
            const dataAll = await resAll.json();
            const dataNone = await resNone.json();
            const dataZaamo = await resZaamo.json();
            const dataWish = await resWish.json();
            const dataGap = await resGap.json();
            const dataShop = await resShop.json();
            if (dataAll.statusCode === 200) {
                dispatch({
                    type: "FILTERED_3M_UNFULFILLED_PRODUCTS_DATA",
                    payload: {
                        updated_at: dataAll.body.updated_at,
                        all: dataAll.body.sales_data,
                        none: dataNone.body.sales_data,
                        zaamo: dataZaamo.body.sales_data,
                        wishlink: dataWish.body.sales_data,
                        goaffpro: dataGap.body.sales_data,
                        shopify: dataShop.body.sales_data,
                    },
                });
            } else {
                toast.error(`Error with Satus Code ${dataAll.statusCode}`, {
                    theme: "colored",
                });
            }
            dispatch(hideLoading());
        } catch (error) {
            dispatch(hideLoading());
            toast.error("Something Went Wrong With Connecting To Server", {
                theme: "colored",
            });
        }
    };

export const getReturningProductsData = () => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/100";

    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            dispatch({
                type: "RETURNING_PRODUCTS_DATA",
                payload: data.body,
            });
        } else {
            toast.error(`Error with Satus Code ${data.statusCode}`, {
                theme: "colored",
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const getSheetNames = async () => {
    let res;
    try {
        const response = await fetch(
            `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/sheetnames`,
            {
                method: "GET",
            }
        );
        const data = await response.json();

        if (data.statusCode === 200) {
            toast.success("Sheet names fetched successfully");
            res = {
                status: 200,
                data: data.data,
            };
        } else {
            toast.error("Error in fetching sheet names");
            res = {
                status: 500,
            };
            console.log("error", data);
        }
    } catch (err) {
        toast.error("Error in fetching sheet names");
        res = { status: 500 };
        console.log("err", err);
    }

    return res;
};

export const addSheetName = (name) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/sheetnames";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify({ name }),
        });
        if (response.status === 403) {
            dispatch(hideLoader());
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("sheet added successfully");
        } else {
            toast.error("sheet name couldn't be updated");
        }
    } catch (err) {
        dispatch(hideLoader());
        console.log("here", err);
        toast.error("Something Went Wrong With Updating sheet", {
            theme: "colored",
        });
    }
};

export const orderBySheetNames = (name) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/sheetnames/orders";
    dispatch(showLoading());
    let payload = {
        name,
        LastEvaluatedKey: null,
    };
    let fetching = true;
    let allOrders = [];
    try {
        while (fetching) {
            const response = await lambdaFetch(URL, {
                method: "POST",

                body: JSON.stringify(payload),
            });
            if (response.status === 403) {
                fetching = false;
                dispatch(hideLoading());
                dispatch(showUnauthorizedModal());
                return;
            }
            const data = await response.json();

            dispatch(hideLoading());
            if (data.statusCode === 200) {
                console.log("data", data);
                allOrders.push(...data.data);
                payload.LastEvaluatedKey = data.LastEvaluatedKey;
                if (!payload.LastEvaluatedKey) {
                    fetching = false;
                }

                toast.success("Fetched orders Successfully.");
            } else {
                fetching = false;
                allOrders = [];
                toast.error(`Server Error ${data.statusCode}`, {
                    theme: "colored",
                });
            }
        }
        dispatch({
            type: "ORDER_TAKEOUT",
            payload: allOrders,
        });
    } catch (err) {
        dispatch(hideLoading());
        console.log("here", err);
        toast.error("Something Went Wrong With Connecting To Database", {
            theme: "colored",
        });
    }
};

export const xpressbessForwardShipment = (orders) => async (dispatch) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/forwardshipment";
    dispatch(showLoading());

    let i = 0;
    let allOrders = [];
    while (i < orders.length) {
        try {
            const response = await fetch(URL, {
                method: "POST",
                body: JSON.stringify(orders[i]),
            });
            const data = await response.json();
            console.log("data", data);
            if (data.statusCode === 200) {
                const currentOrder = orders[i];
                currentOrder.awb_code = String(data.body);
                currentOrder.package_status = "sync#dispatched";
                allOrders.push(currentOrder);
                toast.success("shipped successfully");
            } else {
                console.log("forward shipment error", data);
                toast.error(
                    `Server Error ${data.statusCode} for order ${orders[i].fulfillment_id}`,
                    {
                        theme: "colored",
                    }
                );
            }
        } catch (err) {
            console.log("forward shipment error", err);
            toast.error("Something Went Wrong With Connecting To Database", {
                theme: "colored",
            });
        }
        console.log("order number ", i + 1);
        i++;
    }
    dispatch(hideLoading());
    dispatch({
        type: "ORDER_TAKEOUT",
        payload: allOrders,
    });
};

export const getOrdersByOriginalOrderId = async (id) => {
    let res;
    try {
        const response = await lambdaFetch(
            `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/ordersbyoriginalorderid`,
            {
                method: "POST",
                Headers: {
                    "content/type": "application/json",
                },
                body: JSON.stringify({ company: "LBI", id }),
            }
        );
        const data = await response.json();

        if (data.statusCode === 200) {
            res = {
                status: 200,
                body: data.body,
            };
        } else {
            res = {
                status: 500,
            };
        }
    } catch (err) {
        res = { status: 500 };
        console.log("err", err);
    }

    return res;
};

export const getReverseOrdersByOriginalOrderId = async (id) => {
    let res;
    console.log({ company: "R_LBI", id });
    try {
        const response = await lambdaFetch(
            `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/ordersbyoriginalorderid`,
            {
                method: "POST",
                Headers: {
                    "content/type": "application/json",
                },
                body: JSON.stringify({ company: "R_LBI", id }),
            }
        );
        const data = await response.json();
        console.log(data);
        if (data.statusCode === 200) {
            res = {
                status: 200,
                body: data.body,
            };
        } else if (data.statusCode === 404) {
            res = {
                status: 404,
                body: [],
            };
        } else {
            res = {
                status: 500,
                body: [],
            };
        }
    } catch (err) {
        res = { status: 500 };
        console.log("err", err);
    }

    return res;
};

export const getForwardOrdersByStatus = async (status, date) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/statusandprocessedat";

    const payloadStatus = `sync#${status}`;

    let fetching = true;
    let orders = [];
    let LastEvaluatedKey = null;
    while (fetching) {
        console.log({ LastEvaluatedKey, status: payloadStatus, date });
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    LastEvaluatedKey,
                    status: payloadStatus,
                    date,
                }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                console.log(data);
                const processingData = data.body;
                orders.push(...processingData);
                LastEvaluatedKey = data.LastEvaluatedKey;
                if (!LastEvaluatedKey) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
                console.log(data);
                toast.error("Error fetching orders");
                return {
                    statusCode: 500,
                };
            }
        } catch (error) {
            fetching = false;
            console.log(error);
            toast.error("Error fetching orders", error);
            return {
                statusCode: 500,
            };
        }
    }
    return {
        statusCode: 200,
        data: orders,
    };
};

export const getOrdersByMultipleStatus = async (statusList, date) => {
    console.log("status list", statusList);
    try {
        const results = await Promise.all(
            statusList.map((status) => getForwardOrdersByStatus(status, date))
        );
        console.log(results);
        const isError = results.some((result) => result.statusCode === 500);
        if (isError) {
            return { statusCode: 500 };
        } else {
            return {
                statusCode: 200,
                data: results.flatMap((r) => r.data),
            };
        }
    } catch (error) {
        console.error(error);
        return { statusCode: 500 };
    }
};

export const revertStock = async (order) => {
    // update stock
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/cancelprocessingorders";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify(order),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("stock updated successfully");
            return { statusCode: 200 };
        } else {
            toast.error("Error in reverting stock");
            console.log("Error", data.body);
            return { statusCode: 500 };
        }
    } catch (error) {
        toast.error("server error");
        console.log("server error", error);
        return { statusCode: 500 };
    }
};

export const revertOrderFulfillment = async (order) => {
    const payload = {
        order_id: order.id,
        tracking_number: order.awb_code,
    };

    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/revertfulfillmentstatus";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Cancelled Fulfillment in Shopify");
            console.log("Cancelled fulfillment", data.body);
            const revertStockRes = await revertStock(order);
            return revertStockRes;
        } else {
            console.log("ERROR in fulfillment in shopify", data);
            toast.error("Error in Cancelling Order");
            return { statusCode: 500 };
        }
    } catch (e) {
        console.log("ERROR lambda", e);
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
        return { statusCode: 500 };
    }
};

export const deleteOrder = async (order) => {
    // update stock
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/packetnotready";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify(order),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("deleted successfully");
            return { statusCode: 200 };
        } else {
            toast.error("Error in deleting order");
            console.log("Error", data.body);
            return { statusCode: 500 };
        }
    } catch (error) {
        toast.error("server error");
        console.log("server error", error);
        return { statusCode: 500 };
    }
};

export const searchAWB = async (awb) => {
    let res;
    try {
        const response = await lambdaFetch(
            `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/orderbyawb`,
            {
                method: "POST",
                Headers: {
                    "content/type": "application/json",
                },
                body: JSON.stringify({ awb }),
            }
        );
        const data = await response.json();
        res = {
            status: data.statusCode,
        };
        if (data.statusCode === 200) {
            res = {
                status: 200,
                body: data.body,
            };
        }
    } catch (err) {
        res = { status: 500 };
        console.log("err", err);
    }

    return res;
};

export const manualExchangeSearchOrder = async (
    orderId,
    action = "by_order_number"
) => {
    let res;
    console.log("searching");
    try {
        console.log("payload", { orderId, action });
        const response = await lambdaFetch(
            `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/exchange/manualexchangeorderdetails`,
            {
                method: "POST",
                Headers: {
                    "content/type": "application/json",
                },
                body: JSON.stringify({ orderId, action }),
            }
        );
        const data = await response.json();
        console.log("data", data);
        res = {
            status: data.statusCode,
        };
        if (data.statusCode === 200) {
            res = {
                status: 200,
                body: data.body,
            };
        } else if (data.statusCode === 404) {
            res = {
                status: 404,
            };
            toast.error("Cannot Find order details, Try again");
        }
    } catch (err) {
        res = { status: 500 };
        console.log("err", err);
    }

    return res;
};

export const bulkDispatched = (orders) => {
    return async (dispatch) => {
        dispatch(showFLoading());
        let count = 0;
        while (count < orders.length) {
            try {
                dispatch(
                    markStatusDynamo(
                        orders[count].fulfillment_id,
                        "sync#dispatched"
                    )
                );
            } catch (error) {
                toast.error(
                    "Something Went Wrong With Connecting To Shopify Server",
                    {
                        theme: "colored",
                    }
                );
            }
            count++;
            if (count >= orders.length) {
                dispatch(hideFLoading());
            }
        }
    };
};

export const getDelhiveryWayBills = async (numberOfAwbs) => {
    let res;
    console.log("searching");
    try {
        const response = await lambdaFetch(
            `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/delhivery/getwaybills`,
            {
                method: "POST",
                Headers: {
                    "content/type": "application/json",
                },
                body: JSON.stringify({ count: numberOfAwbs }),
            }
        );
        const data = await response.json();
        console.log("data", data);
        res = {
            status: data.statusCode,
        };
        if (data.statusCode === 200) {
            res = {
                status: 200,
                body: data.body,
            };
        } else if (data.statusCode === 404) {
            res = {
                status: 404,
            };
            toast.error("Error, Try again");
        }
    } catch (err) {
        res = { status: 500 };
        console.log("err", err);
    }

    return res;
};

export const cancelDelhiveryShipment = async (awb) => {
    let res;
    try {
        const response = await lambdaFetch(
            `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/delhivery/cancelshipment`,
            {
                method: "POST",
                Headers: {
                    "content/type": "application/json",
                },
                body: JSON.stringify({ awb }),
            }
        );
        const data = await response.json();
        console.log("data", data);
        res = {
            status: data.statusCode,
        };
        if (data.statusCode === 200) {
            const delhiveryResponse = data.body;
            console.log("delhivery response", delhiveryResponse);
            if (delhiveryResponse.status) {
                toast.error(delhiveryResponse.remark);
            } else {
                toast.error(delhiveryResponse.error);
            }
            res = {
                status: 200,
                body: data.body,
            };
        } else {
            res = { status: 500 };
        }
    } catch (err) {
        res = { status: 500 };
        toast.error("Something went wrong while canceling shipment");
        console.log("err", err);
    }

    return res;
};

export const uploadPayloadToS3 = async (payload, name) => {
    let res;
    const filename = `${new Date().toISOString()}_${name}`;
    try {
        const response = await lambdaFetch(
            `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/uploadtos3`,
            {
                method: "POST",
                Headers: {
                    "content/type": "application/json",
                },
                body: JSON.stringify({ payload, filename }),
            }
        );
        const data = await response.json();
        if (data.statusCode === 200) {
            res = {
                status: 200,
            };
        } else {
            res = { status: 500 };
        }
    } catch (err) {
        res = { status: 500 };
        console.log("err", err);
    }

    return res;
};

export const getDelhiveryShipmentDetails = (awb) => {
    console.log("awb", awb);
    async function getXBShipmentData() {
        const Url =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/delhivery/getshipmenttracking";
        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ AWB: awb }),
        });

        const data = await response.json();
        return data;
    }
    return (dispatch) => {
        getXBShipmentData()
            .then((data) => {
                console.log("data", data);
                if (data === 403) {
                    dispatch(showUnauthorizedModal());
                    return;
                }
                if (data.statusCode === 200) {
                    dispatch({
                        type: "DELHIVERY_ORDER_HISTORY",
                        payload: [data.body[0].Shipment],
                    });
                } else if (data.statusCode === 404) {
                    dispatch({
                        type: "DELHIVERY_ORDER_HISTORY",
                        payload: "",
                    });
                    toast.error("Shipment Details for Given AWB not Found");
                }
            })
            .catch((error) => {
                toast.error(
                    "Shipment Details Error in Loading.Please Try Again",
                    {
                        theme: "colored",
                    }
                );
            });
    };
};

export const removeDelhiveryShipmentDetails = () => {
    return {
        type: "DELHIVERY_REMOVE_ORDER_HISTORY",
    };
};

export const createRefund = async (payload) => {
    let res;
    try {
        const response = await lambdaFetch(
            `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/refund`,
            {
                method: "POST",
                Headers: {
                    "content/type": "application/json",
                },
                body: JSON.stringify(payload),
            }
        );
        const data = await response.json();
        if (data.statusCode === 200) {
            res = {
                status: 200,
                body: data.body,
            };
        } else if (data.statusCode === 400) {
            res = {
                status: 404,
                body: data.body,
            };
        } else {
            res = {
                status: 500,
            };
        }
    } catch (err) {
        res = { status: 500 };
        console.log("err", err);
    }

    return res;
};

export const packetNotReady = async (order) => {
    const payload = {
        order_id: order.id,
        tracking_number:
            order.awb_code === "changed" ? order.old_awb_code : order.awb_code,
    };

    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/revertfulfillmentstatus";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Cancelled Fulfillment in Shopify");
            const deleteOrderRes = await deleteOrder(order);
            return deleteOrderRes;
        } else {
            console.log("ERROR in fulfillment in shopify", data);
            toast.error("Error in Cancelling Order");
            return { statusCode: 500 };
        }
    } catch (e) {
        console.log("ERROR lambda", e);
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
        return { statusCode: 500 };
    }
};

export const cancelOrder = async (order) => {
    let res;
    console.log({ orderId: order.id });
    try {
        const response = await fetch(
            `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/cancelorder`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({ orderId: order.id }),
            }
        );

        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Cancelled in Shopify");
            const deleteOrderRes = await deleteOrder(order);
            return deleteOrderRes;
        } else {
            toast.error("Cannot cancel order");
            res = {
                status: 500,
            };
        }
    } catch (err) {
        res = { status: 500 };
    }

    return res;
};

export const markAndCloseFulfillment = async (order) => {
    const payload = {
        id: order.id,
        tracking_number: String(order.awb_code),
    };
    console.log(payload);

    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/addNoteShopify";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Order Tracking Removed & Tag Added In Shopify");
            const revertStockRes = await revertStock(order);
            return revertStockRes;
        } else {
            toast.error("Error in Closing Order");
        }
    } catch (e) {
        console.log("ERROR lambda", e);
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const cancelAndPnrOrder = async (order) => {
    const payload = {
        id: order.id,
        tracking_number: String(order.awb_code),
    };
    console.log(payload);

    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/addNoteShopify";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        if (response.status === 403) {
            return 403;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Order Tracking Removed & Tag Added In Shopify");
            const deleteOrderRes = await deleteOrder(order);
            return deleteOrderRes;
        } else {
            toast.error("Error in Closing Order");
        }
    } catch (e) {
        console.log("ERROR lambda", e);
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const getStateWiseSalesData = () => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/29";

    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();

        if (data.statusCode === 200) {
            dispatch({
                type: "STATE_WISE_SALES_DATA",
                payload: data.body,
            });
        } else {
            toast.error(`Error with Satus Code ${data.statusCode}`, {
                theme: "colored",
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const getRepeatingProductsData = () => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/31";

    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        console.log(data);
        if (data.statusCode === 200) {
            dispatch({
                type: "REPEATING_PRODUCTS_DATA",
                payload: data.body,
            });
        } else {
            toast.error(`Error with Satus Code ${data.statusCode}`, {
                theme: "colored",
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const getUnreliableOrdersData = () => async (dispatch) => {
    dispatch(showLoading());
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/30";

    try {
        const response = await fetch(URL, {
            method: "GET",
        });
        if (response.status === 403) {
            dispatch(showUnauthorizedModal());
            return;
        }
        const data = await response.json();
        console.log(data);
        if (data.statusCode === 200) {
            dispatch({
                type: "UNRELIABLE_ORDERS_DATA",
                payload: data.body,
            });
        } else {
            toast.error(`Error with Satus Code ${data.statusCode}`, {
                theme: "colored",
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
    }
};

export const fulfillSingleOrderInShopify = async (order) => {
    const payload = {
        order_id: order.id,
        // location_id: orders[0][i].location_id || "65807843485",
        line_items: order.order_items.map((o) => ({
            id: o.id,
            quantity: o.units,
        })),
        tracking_urls: [
            `https://www.delhivery.com/track/package/${order.awb_code}`,
        ],
        tracking_number: parseInt(order.awb_code),
        shipping_partner: "Delhivery",
    };
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updatefulfillmentstatus";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",

            body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success(`Fulfilled AWB ${order.awb_code}`);
            return {
                success: true,
                message: `Fulfilled AWB ${order.awb_code}`,
            };
        } else {
            toast.error(`Shopify Error of AWB ${order.awb_code}`);
            return {
                success: false,
                message: `Shopify Error of AWB ${order.awb_code}`,
            };
        }
    } catch (e) {
        toast.error("Could not fulfill. Lambda error");
        return {
            success: false,
            message: "Could not fulfill. Lambda error",
        };
    }
};

export const updatePNROrder = async (order) => {
    console.log("order", order);
    const Url =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/updateshiprocketorder";

    try {
        const response = await lambdaFetch(Url, {
            method: "POST",

            body: JSON.stringify({ order }),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast("Order Updated successfully");
            return {
                success: true,
            };
        } else {
            toast("Order Not Updated");
            return {
                success: false,
            };
        }
    } catch (error) {
        toast("Order not updated , server error");
        return {
            success: false,
        };
    }
};

export const packetNotReadySelectedProducts = async (order, newOrder) => {
    const payload = {
        order_id: order.id,
        tracking_number:
            order.awb_code === "changed" ? order.old_awb_code : order.awb_code,
    };

    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/revertfulfillmentstatus";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Cancelled Fulfillment in Shopify");
            //new fulfillment
            // create new order
            const fulfillment = await fulfillSingleOrderInShopify(newOrder);
            if (fulfillment.success) {
                //update order in dynamodb
                const orderUpdate = await updatePNROrder(newOrder);
                if (orderUpdate) {
                    return { statusCode: 200 };
                } else {
                    return { statusCode: 500 };
                }
            } else {
                return { statusCode: 500 };
            }
        } else {
            console.log("ERROR in fulfillment in shopify", data);
            toast.error("Error in Cancelling Order");
            return { statusCode: 500 };
        }
    } catch (e) {
        console.log("ERROR lambda", e);
        toast.error("Something Went Wrong With Connecting To Server", {
            theme: "colored",
        });
        return { statusCode: 500 };
    }
};

export const updateUnreliableOrdersTag = async (id, tag) => {
    const payload = {
        tag: tag,
        orderId: id,
    };

    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/updateunreliableorderstag";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Tag updated successfully");
            return { statusCode: 200, body: data.body };
        } else {
            toast.error("Cannot Update Tag");
            return { statusCode: 500 };
        }
    } catch (e) {
        console.log(e);
        toast.error("Something Went Wrong With Connecting To Server");
        return { statusCode: 500 };
    }
};

export const updateUnreliableOrdersS3 = async (orders) => {
    const payload = {
        fileName: 30,
        payload: orders,
    };

    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/uploadtos3";

    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success("Order updated successfully");
            return { statusCode: 200 };
        } else {
            toast.error("Cannot Update Order");
            return { statusCode: 500 };
        }
    } catch (e) {
        toast.error("Something Went Wrong With Connecting To Server");
        return { statusCode: 500 };
    }
};

export const sendToShiprocketQueue = async (orders, part) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shiprocket/sendtoqueue";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            body: JSON.stringify(orders),
        });
        const data = await response.json();
        if (data.statusCode === 200) {
            toast.success(`Sended to queue for ${part}`);
        } else {
            toast.error(`Error sending to queue for ${part}`);
        }
    } catch (error) {
        toast.error("something went wrong");
    }
};

export const getShiprocketOrdersByProcessedAt = async (date) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shiprocket/orders";

    let fetching = true;
    let orders = [];
    let LastEvaluatedKey = null;
    while (fetching) {
        console.log({ LastEvaluatedKey, date });
        try {
            const response = await lambdaFetch(URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    LastEvaluatedKey,
                    date,
                }),
            });
            if (response.status === 403) {
                fetching = false;
                return 403;
            }
            const data = await response.json();
            if (data.statusCode === 200) {
                console.log(data);
                const processingData = data.body;
                orders.push(...processingData);
                LastEvaluatedKey = data.LastEvaluatedKey;
                if (!LastEvaluatedKey) {
                    fetching = false;
                }
            } else {
                orders = [];
                fetching = false;
                console.log(data);
                toast.error("Error fetching orders");
                return {
                    statusCode: 500,
                };
            }
        } catch (error) {
            fetching = false;
            console.log(error);
            toast.error("Error fetching orders", error);
            return {
                statusCode: 500,
            };
        }
    }
    return {
        statusCode: 200,
        data: orders,
    };
};

export const getOrdersInS3ByDate = async (date) => {
    try {
        const FILENAME_URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/s3files/getfilenames";
        const FILE_URL =
            "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/s3files/getfile";

        const filenames_res = await lambdaFetch(FILENAME_URL, {
            method: "POST",
            body: JSON.stringify({ date }),
        });
        const filenames_data = await filenames_res.json();
        if (filenames_data.statusCode === 200) {
            const filenames = filenames_data.body;
            let orders = [];
            for (let i = 0; i < filenames.length; i++) {
                const file_res = await lambdaFetch(FILE_URL, {
                    method: "POST",
                    body: JSON.stringify({ file_key: filenames[i] }),
                });
                const file_data = await file_res.json();
                if (file_data.statusCode === 200) {
                    orders = [...orders, ...file_data.body];
                } else {
                    toast.error("Something Went Wrong With Server");
                    return {
                        statusCode: 500,
                    };
                }
            }
            return {
                statusCode: 200,
                body: orders,
            };
        } else {
            toast.error("Something Went Wrong With Server");
            return {
                statusCode: 500,
            };
        }
    } catch (error) {
        toast.error("Something Went Wrong With Server");
        return {
            statusCode: 500,
        };
    }
};

export const getOrderDetailsGQL = async (id) => {
    const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/getOrderByOrderIdShopify";
    try {
        const response = await lambdaFetch(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id }),
        });
        if (response.status === 403) {
            toast.error("Error Unauthorized!!");
            return false;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
            return data.order;
        } else {
            toast.error("Error fetching order");
            return false;
        }
    } catch (error) {
        console.log(error);
        toast.error("Error fetching orders");
        return false;
    }
};
